import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ActionPlateElem from './ActionPlateElem'
import { resetStatuses } from '../publickFunc'



const types = [
    {
        type: 'd_pd_id',
        name: 'ПД'
    },
    {
        type: 'd_gepd_id',
        name: 'ГЭ ПД'
    },
    {
        type: 'd_ssr_id',
        name: 'ССР'
    },
    {
        type: 'd_sdnf_id',
        name: 'СД (в н/ф)'
    },
    {
        type: 'd_sdrf_id',
        name: 'СД (в р/ф)'
    },
    {
        type: 'd_resd_id',
        name: 'ГЭ СД'
    }]



const ActionPlate = ({ activeTr, top, show, setShow }) => {

    const [counterObj, setCounter] = useState(null)



    useEffect(() => {
        if (activeTr) {
            let arr = types.map(elem => activeTr[elem.type] ? activeTr[elem.type].status.slug : 'ne-zagruzen')
            setCounter(resetStatuses(arr))
        }
    }, [activeTr])


    useEffect(() => {
        document.addEventListener('click', clickHandler)
        return () => { document.removeEventListener('click', clickHandler) }
    }, [])

    function clickHandler(e) {
        let visibleActions = document.querySelector('.visible_action_plate')
        if (visibleActions && !e.target.closest('.visible_action_plate') && !e.target.closest('tr')) setShow(false)
    }

    return (
        <div style={top && { top }} className={`action_plate ${show ? 'visible_action_plate' : ''}`}>
            <div className="action_plate_head">
                <div className="indicator">
                    <div className="counter">{counterObj && `${counterObj.counter}/${counterObj.secondCouter}`}</div>
                </div>
                <div className="title">{activeTr && activeTr.name}</div>
            </div>
            <div className="action_plate_content">
                <div className="plate_str plate_headers">
                    <div>Документы</div>
                    <div>Статус</div>
                    <div>Действия</div>
                </div>
            </div>
            {types.map(type => {
                return <ActionPlateElem type={type} key={type.type} data={activeTr ? activeTr[type.type] : null} />
            })}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        activeTr: state.ispoldocums.activeTr,
        show: state.ispoldocums.showActions,
        top: state.ispoldocums.top
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setShow: (showActions) => dispatch({ type: 'SET_SHOW_ACTIONS', showActions })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionPlate)