import React from 'react';
import {dateFormatter} from '../publickFunc'




const VotingElem = ({ data }) => {
    return (
        <div className={`confirm_item ${data.status.slug}`}>
            <div className="confirm_user">{`${data.user.sname} ${data.user.name[0] ? data.user.name[0] : ''}`}</div>
            <div className="confirm_status">
                {data.status.status}
                <div className="confirm_date">{dateFormatter(data.updated_at)}</div>
            </div>
        </div>
    )
}


export default VotingElem