const initialState = {
	meetingsUpcoming: [],
	meetingsPast: [],
	meetingsAgenda: [],
	meetingsProtocol: [],
	issues: [],
	activeMeeting: null,
	activeIssue: null,
}


export function meetingsReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_MEETINGS':
			return {
				...state,
				meetingsUpcoming: action.meetingsUpcoming,
				meetingsPast: action.meetingsPast,
			}
		case 'SET_ACTIVE_MEETING':
			return {
				...state,
				activeMeeting: action.activeMeeting
			}
		case 'SET_MEETINGS_AGENDA':
			return {
				...state,
				meetingsAgenda: action.meetingsAgenda
			}
		case 'SET_MEETINGS_PROTOCOL':
			return {
				...state,
				meetingsProtocol: action.meetingsProtocol
			}
		case 'SET_MEETING_PAST':
			return {
				...state,
				meetingsPast: action.meetingsPast
			}
		case 'SET_MEETING_UPCOMING':
			return {
				...state,
				meetingsUpcoming: action.meetingsUpcoming
			}
		case 'SET_FILE_MEETING':
			return {
				...state,
				activeMeeting: action.activeMeeting,
			}
		case 'SET_ISSUES_MEETING':
			return {
				...state,
				issues: action.issues
			}
		case 'SET_ISSUES_FILES':
			return {
				...state,
				issues: action.issues
			}
		case 'SET_ACTIVE_ISSUES':
			return {
				...state,
				activeIssue: action.activeIssue,
			}
		default:
			return state
	}
}