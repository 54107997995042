import React from 'react';
import { connect } from 'react-redux';


const OrgListElem = ({ comp, userList, currentComp, setCurrentComp }) => {
    return (
        <div
            onClick={() => setCurrentComp(comp)}
            className={`user_plate_elem org_elem ${(currentComp && (comp.id === currentComp.id)) ? 'current' : ''}`}
        >
            <div className="user_name">{comp.name}</div>
            <div className="user_org">{userList.filter(elem => (elem.company_ids && elem.company_ids.includes(comp.id))).length}</div>
        </div>
    )
}

const stateToProps = state => {
    return {
        userList: state.users.userList,
        currentComp: state.userplate.currentComp
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentComp: currentComp => dispatch({ type: 'SET_CURRENT_COMP', currentComp })
    }
}


export default connect(stateToProps, mapDispatchToProps)(OrgListElem)