import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';


const NotifLineElem = ({eventType, docActions, userSubs, selfSub, currentUser, adminWatchSelf}) => {
    const [email, setEmail] = useState(false)
    const [site, setSite] = useState(false)
    const [reqMethod, setMethod] = useState(null)


    useEffect(() => {
        let method = adminWatchSelf ? 'POST' : 'PATCH'
        setMethod(method)
    }, [adminWatchSelf])

    useEffect(() => {
        let sub = userSubs.find(elem => elem.document_event_type_id == eventType.id)
        if (sub) {
            setEmail(!!sub.send)
            setSite(!!sub.active)
        }
    }, [userSubs, eventType])


    return (
        <div className="notif_line_elem">
            <div className="elem_title">{docActions[eventType.document_action_id].title}</div>
            <div onClick={() => {
                let method = userSubs.find(elem => elem.document_event_type_id == eventType.id) ? 'PATCH' : 'POST'
                if (site) {
                    selfSub(currentUser.id, {active: 0, send: email}, eventType.id, method)
                } else {
                    selfSub(currentUser.id, {active: 1, send: email}, eventType.id, method)
                }
                }} className={`notif_elem_check ${site ? 'checked' : ''}`}>На сайте</div>
            <div onClick={() => {
                let method = userSubs.find(elem => elem.document_event_type_id == eventType.id) ? 'PATCH' : 'POST'
                if (email) {
                    selfSub(currentUser.id, {active: site, send: 0}, eventType.id, method)
                } else {
                    selfSub(currentUser.id, {active: site, send: 1}, eventType.id, method)
                }
                }} className={`notif_elem_check ${email ? 'checked' : ''}`}>E-Mail</div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        docActions: state.subscribe.docActions,
        userSubs: state.subscribe.userSubs,
        currentUser: state.userplate.currentUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        selfSub: (id, body, eventTypeId, requestMethod) => dispatch({type:'USER_SELF_SUB', id, body, eventTypeId, requestMethod})
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(NotifLineElem)