import React, { useState } from 'react';
import { connect } from 'react-redux';
import Password from './Password'




const Login = ({ onLogin }) => {
    const [email, setEmail] = useState(''),
        [password, setPassword] = useState('')

    function onSubmitHandler(e) {
        e.preventDefault()
    }

    return (
        <div id="login">
            <form action="/" onSubmit={onSubmitHandler}>
                <h1>Логин</h1>
                <input type="email" name="email" placeholder="Email" value={email} onChange={e => setEmail(e.currentTarget.value)} />
                <Password val={password} id="password_input" placeholder="Пароль" changeHandler={e => setPassword(e.currentTarget.value)} />
                <button onClick={() => onLogin(email, password)}>Войти</button>
            </form>
            <div className="img"></div>
        </div>
    )
}


const mapDispatchToProps = dispatch => {
    return {
        onLogin: (email, password) => dispatch({ type: 'LOGIN', email, password })
    }
}

export default connect(null, mapDispatchToProps)(Login)