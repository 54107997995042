import React, { useState, useEffect } from 'react'
import { fetchData, deleteCookie } from '../publickFunc'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import NavElem from './NavElem'
import ScrollArea from 'react-scrollbar'
import AsideRef from './AsideRef'

const Aside = ({
	elems,
	deleteTokens,
	userName,
	fetchUsers,
	fetchOrgs,
	typeLoc,
	setSubMode,
	fetchSubInfo,
	fetchNotifList,
	maxRole,
	speed,
}) => {
	const [showOpt, setShow] = useState(false)

	useEffect(() => {
		document.addEventListener('click', outSideClick)
		return () => {
			document.removeEventListener('click', outSideClick)
		}
	}, [])

	useEffect(() => {
		fetchUsers()
		fetchOrgs()
		fetchSubInfo()
	}, [])

	function outSideClick(e) {
		let visibleActions = document.querySelector('.visible_actions')
		if (
			visibleActions &&
			!e.target.closest('.visible_actions') &&
			!e.target.closest('.user_name')
		)
			setShow(false)
	}

	function exitHanler() {
		fetchData(
			'/logout',
			() => {
				deleteCookie('access_token_rg')
				deleteCookie('email')
				deleteCookie('roles')
				deleteCookie('user_name')
				deleteCookie('user_id')
				deleteTokens()
				window.location.pathname = '/login'
			},
			null,
			'POST',
		)
	}

	return (
		<aside>
			<div className='logo'></div>
			<div
				className='user'
				onClick={e => {
					e.stopPropagation()
					setShow(!showOpt)
				}}>
				<div className='user_name'>{userName}</div>
				<div className={`user_actions ${showOpt ? 'visible_actions' : ''}`}>
					{/* {(+maxRole < 3) && <div className="user_action_elem" onClick={() => setSubMode(true)}>Подписки</div>} */}
					<Link className='user_action_elem' to='/users'>
						Участники
					</Link>
					<div className='user_action_elem exit' onClick={exitHanler}>
						Выйти
					</div>
				</div>
			</div>
			<nav>
				<ScrollArea
					speed={speed}
					horizontal={false}
					vertical={true}
					style={{ height: '100%' }}
					smoothScrolling={true}
					verticalContainerStyle={{ width: 4 }}
					verticalScrollbarStyle={{ width: 4, marginLeft: 0 }}
					stopScrollPropagation={true}>
					{elems.map(elem => (
						<NavElem
							active={typeLoc == elem.type}
							key={elem.title}
							title={elem.title}
							type={elem.type}
						/>
					))}
				</ScrollArea>
			</nav>
			<AsideRef />
			<div className='support'>
				<div>Техподдержка</div>
				<div>
					<a href='mailto:tvbavaro@gmail.com'>tvbavaro@gmail.com</a>
				</div>
			</div>
		</aside>
	)
}

const mapStateToProps = state => {
	return {
		userName: state.auth.userName,
		type: state.document.doctype,
		maxRole: state.auth.maxRole,
		speed: state.auth.speed,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		deleteTokens: () =>
			dispatch({
				type: 'SET_ACCESS_TOKEN',
				email: null,
				accessToken: null,
				userName: null,
			}),
		fetchUsers: () => dispatch({ type: 'FETCH_USERS' }),
		fetchOrgs: () => dispatch({ type: 'FETCH_ORGS' }),
		setSubMode: subMode => dispatch({ type: 'SET_SUB_MODE', subMode }),
		fetchSubInfo: () => dispatch({ type: 'FETCH_SUB_INFO' }),
		// fetchNotifList: () => dispatch({ type: 'FETCH_NOTIF_LIST' })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Aside)
