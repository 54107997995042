import {getCookie} from '../../publickFunc'

const initialState = {
    userList: [],
    lowRoles: [],
    currentUserId: getCookie('user_id')
}


export function usersreducer(state=initialState, action) {
    switch (action.type) {
        case 'ADD_USERS': 
            return {
                ...state,
                userList: action.userList,
                lowRoles: action.lowRoles
            }
        case 'SET_USER_ID':
            return {
                ...state,
                currentUserId: action.currentUserId
            }
        default: 
            return state
    }
}