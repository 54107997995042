import React, { useEffect, useState } from 'react'
import ScrollArea from 'react-scrollbar'
import { connect } from 'react-redux'
import FileElem from './FileElem'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import ruLocale from 'date-fns/locale/ru'
import {
	MuiPickersUtilsProvider,
	DatePicker,
	TimePicker,
} from '@material-ui/pickers'

const MeetingsActivePlate = ({
	speed,
	setType,
	saveMeeting,
	meet,
	uploadProtocolMeet,
	deleteMeeting,
	deleteFileMeeting,
	uploadAgendaMeet,
	addIssues,
	issuesState,
	delIssue,
	editIssues,
	deleteIssueFile,
	uploadFileIssue,
}) => {
	const [completed, setCompleted] = useState(false)
	const [agendaID, setAgendaID] = useState([])
	const [agenda, setAgenda] = useState([undefined])
	const [protocolID, setProtocolID] = useState(null)
	const [protocol, setProtocol] = useState([null])
	const [title, setTitle] = useState(null)
	const [dateMeeting, setDate] = useState(new Date(meet.date))
	const [timeMeeting, setTime] = useState(new Date(`${meet.date}T${meet.time}`))
	const [editTitle, setEditTitle] = useState(false)
	const [editIssue, setEditIssue] = useState('')
	const [editIssueTitle, setEditIssueTitle] = useState('')
	const [issues, setIssues] = useState([])
	const [newIssue, setNewIssue] = useState('')
	const [fileIssue, setFileIssue] = useState([])

	useEffect(() => {
		if (meet.title) {
			setTitle(meet.title)
		} else {
			setTitle('Новое заседание')
		}

		if (meet.completed === 1) {
			setCompleted(true)
		} else {
			setCompleted(false)
		}

		if (meet.date) {
			setDate(meet.date)
		}

		if (meet.time && meet.date) {
			setTime(`${meet.date}T${meet.time}`)
		}
	}, [meet.title, meet.completed, meet.time, meet.date])

	useEffect(() => {
		if (issuesState && issuesState.length > 0) {
			setIssues(issuesState)
		} else {
			setIssues([])
		}
	}, [issuesState])

	useEffect(() => {
		if (meet.files.length > 0) {
			meet.files
				.filter(elem => elem.type === 'protocol')
				.forEach(i => {
					setProtocol([i])
					setProtocolID(i.id)
				})
			const uploadAgenda = meet.files
				.filter(elem => elem.type === 'agenda')
				.map(i => i)
			setAgenda(uploadAgenda)
		} else {
			setAgenda([undefined])
		}
	}, [meet.files])

	useEffect(() => {
		if (protocol.length > 0 && protocol[0] !== null && !protocolID) {
			uploadProtocolMeet(meet.id, protocol[0])
		} else if (protocol.length > 0 && protocol[0] === null && protocolID) {
			deleteFileMeeting(meet.id, protocolID)
			setProtocol([])
			setProtocolID([])
		}
	}, [protocol])

	useEffect(() => {
		if ((agenda.length === 1) & (agenda[0] === null) || agenda.length === 0)
			setAgenda([undefined])
	}, [agenda])

	useEffect(() => {
		if (agenda) {
			let agendaIDClone = agendaID.slice()
			let agendaClone = agenda.slice()
			agendaClone.forEach(i => {
				if (i !== null && i !== undefined) {
					if (!i.id) {
						uploadAgendaMeet(meet.id, i)
					} else {
						agendaIDClone.push(i.id)
						agendaIDClone = Array.from(new Set(agendaIDClone))
					}
				}
			})

			agendaClone.forEach((elem, ind) => {
				if (agendaID[ind] && elem === null) {
					deleteFileMeeting(meet.id, agendaID[ind])
					agendaClone.splice(ind, 1)
					agendaIDClone.splice(ind, 1)
					setAgenda(agendaClone)
				}
			})
			setAgendaID(agendaIDClone)
		}
	}, [agenda])

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
			<div className='meetings_plate'>
				<ScrollArea
					speed={speed}
					horizontal={false}
					vertical={true}
					smoothScrolling={true}
					verticalContainerStyle={{ width: 6 }}
					verticalScrollbarStyle={{ width: 6, marginLeft: 0 }}
					stopScrollPropagation={true}>
					{!editTitle && (
						<h2 className='plate-title'>
							{title}
							<span
								className='pencil'
								onClick={() => setEditTitle(true)}></span>
						</h2>
					)}
					<form className='meetings_plate-form'>
						{editTitle && (
							<div className='meeting-input'>
								<input
									type='text'
									value={title}
									onChange={e => setTitle(e.target.value)}
									placeholder='Заголовок'
									className='meeting-title'
								/>
								<span
									className='send'
									onClick={() => setEditTitle(false)}></span>
							</div>
						)}
						<h3 className='plate-subtitle'>Дата и время начала</h3>
						<div className='meeting-time'>
							<DatePicker
								variant='inline'
								format='dd.MM.yyyy'
								margin='normal'
								placeholder='дд.мм.гггг'
								value={dateMeeting}
								onChange={date => setDate(date)}
								className='meeting-date'
							/>
							<TimePicker
								ampm={false}
								disableToolbar
								format='HH:mm'
								variant='inline'
								placeholder='чч:мм'
								value={timeMeeting}
								onChange={time => setTime(time)}
								className='meeting-date'
							/>
						</div>
						<div className='custom-checkbox'>
							<input
								checked={completed}
								onChange={() => setCompleted(!completed)}
								type='checkbox'
								id='check'
							/>
							<label htmlFor='check'>Заседание уже закончено</label>
						</div>
						<div className='fields_upload'>
							<h3 className='plate-subtitle'>Повестка</h3>
							<div className='files'>
								{agenda.length > 0 &&
									agenda.map(
										(elem, ind) =>
											elem !== null && (
												<FileElem
													key={ind}
													arr={agenda}
													setFunc={setAgenda}
													ind={ind}
												/>
											),
									)}
								<div
									className='add-file'
									onClick={() => {
										const clone = agenda.slice()
										clone.push(undefined)
										setAgenda(clone)
									}}>
									<div className='tooltip'>Добавить повестку</div>
								</div>
							</div>
							{completed && (
								<>
									<h3 className='plate-subtitle'>Протокол</h3>
									<FileElem arr={protocol} setFunc={setProtocol} ind={0} />
								</>
							)}
						</div>
						{completed && (
							<div className='meeting-issues'>
								<h3 className='plate-subtitle'>Ключевые вопросы</h3>
								<h4 className='issues-subtitle'>
									Запишите вопросы, которые у вас возникли на заседании
								</h4>
								<div className='meeting-input'>
									<label className='add-file-issues'>
										<input
											type='file'
											hidden
											onChange={e => setFileIssue([e.currentTarget.files[0]])}
										/>
									</label>
									<input
										type='text'
										value={newIssue}
										onChange={e => setNewIssue(e.target.value)}
										placeholder='Вопрос'
										className='meeting-title'
									/>
									<span
										className='send'
										onClick={() => {
											addIssues(meet.id, newIssue, fileIssue)
											setFileIssue([])
											setNewIssue('')
										}}></span>
								</div>
								{issues.length > 0 &&
									issues.map(elem => (
										<>
											<div
												key={elem.id}
												className={`issue-item ${
													editIssue === elem.id ? 'issue-item-edit' : ''
												}`}>
												{editIssue !== elem.id && (
													<>
														<div className='issue-title'>
															<div>{elem.title}</div>
															<div className='issue-actions'>
																<span
																	className='issue-edit-icon'
																	onClick={() => {
																		setEditIssue(elem.id)
																		setEditIssueTitle(elem.title)
																	}}></span>
																<span
																	className='issue-del-icon'
																	onClick={() =>
																		delIssue(meet.id, elem.id)
																	}></span>
															</div>
														</div>
														{elem.files && elem.files.length > 0 ? (
															<div className='issue-files-list'>
																{elem.files.map(file => (
																	<div
																		key={meet.id + elem.id + file.id}
																		className='issue-file-item'>
																		<a
																			href={file.url}
																			download
																			className='issue-file-name'>{`${file.name}.${file.ext}`}</a>
																	</div>
																))}
															</div>
														) : (
															editIssue !== elem.id && (
																<span className='issue-nofiles'>
																	Файлы отсутвуют
																</span>
															)
														)}
													</>
												)}
												{editIssue === elem.id && (
													<>
														<div className='issue-edit'>
															<textarea
																type='text'
																value={editIssueTitle}
																onChange={e =>
																	setEditIssueTitle(e.target.value)
																}
																autoFocus></textarea>
														</div>
														<div className='issue-edit_action-files'>
															<label className='add-file-issue'>
																<input
																	type='file'
																	hidden
																	onChange={e =>
																		uploadFileIssue(
																			meet.id,
																			e.currentTarget.files[0],
																			elem.id,
																		)
																	}
																/>
															</label>
															{elem.files && elem.files.length > 0 ? (
																<div className='issue-files-list'>
																	{elem.files.map(file => (
																		<div
																			key={meet.id + elem.id + file.id}
																			className='issue-file-item'>
																			<span className='issue-file-name'>{`${file.name}.${file.ext}`}</span>
																			<span
																				className='issue-file-del'
																				onClick={() =>
																					deleteIssueFile(
																						meet.id,
																						elem.id,
																						file.id,
																					)
																				}></span>
																		</div>
																	))}
																</div>
															) : (
																<span className='issue-nofiles'>
																	Файлы отсутвуют
																</span>
															)}
														</div>
													</>
												)}
											</div>
											{editIssue === elem.id && (
												<div className='issue-edit-actions'>
													<span
														className='issue-complete btn'
														onClick={() => {
															editIssues(meet.id, elem.id, editIssueTitle)
															setEditIssue(false)
															setEditIssueTitle('')
														}}>
														Сохранить
													</span>
													<span
														className='issue-cancel btn'
														onClick={() => {
															setEditIssue('')
															setEditIssueTitle('')
														}}>
														Отмена
													</span>
												</div>
											)}
										</>
									))}
							</div>
						)}
					</form>
				</ScrollArea>
				<div className='meetings_plate-actions'>
					<div
						className='link-btn remove'
						onClick={() => {
							deleteMeeting(meet.id, completed)
							setType('no-meetings')
						}}>
						Удалить
					</div>
					<div className='btn-group'>
						<div className='link-btn' onClick={() => setType('no-meetings')}>
							Отменить
						</div>
						<div
							className='btn'
							onClick={() => {
								saveMeeting(meet.id, title, dateMeeting, timeMeeting, completed)
							}}>
							{(!completed && meet.completed === 0) ||
							(completed && meet.completed === 1)
								? `Сохранить`
								: !completed && meet.completed === 1
								? `Вернуть`
								: `Завершить`}
						</div>
					</div>
				</div>
			</div>
		</MuiPickersUtilsProvider>
	)
}

const mapStateToProps = state => ({
	speed: state.auth.speed,
	meetingsUpcoming: state.meetings.meetingsUpcoming,
	meetingsPast: state.meetings.meetingsPast,
	meet: state.meetings.activeMeeting,
	issuesState: state.meetings.issues,
})

const mapDispatchToProps = dispatch => ({
	saveMeeting: (id, title, dateMeeting, timeMeeting, completed) =>
		dispatch({
			type: 'SAVE_MEETING',
			id,
			title,
			dateMeeting,
			timeMeeting,
			completed,
		}),
	uploadProtocolMeet: (id, file) =>
		dispatch({ type: 'UPLOAD_PROTOCOL', id, file }),
	uploadAgendaMeet: (id, file) => dispatch({ type: 'UPLOAD_AGENDA', id, file }),
	deleteMeeting: (id, completed) =>
		dispatch({ type: 'DELETE_MEETING', id, completed }),
	deleteFileMeeting: (id, file) =>
		dispatch({ type: 'DELETE_FILE_MEETING', id, file }),
	addIssues: (id, issues, files) =>
		dispatch({ type: 'ADD_ISSUES', id, issues, files }),
	delIssue: (id, issue) => dispatch({ type: 'DELETE_ISSUE', id, issue }),
	editIssues: (id, issue, title) =>
		dispatch({ type: 'EDIT_ISSUE', id, issue, title }),
	deleteIssueFile: (id, issueId, fileId) =>
		dispatch({ type: 'DELETE_FILE_ISSUE', id, issueId, fileId }),
	uploadFileIssue: (id, file, issuesId) =>
		dispatch({ type: 'UPLOAD_FILE_ISSUE', id, file, issuesId }),
})

export default connect(mapStateToProps, mapDispatchToProps)(MeetingsActivePlate)
