import React, { useState } from 'react';
import { connect } from 'react-redux';
import VotingList from './VotingList'
import VotingListChanger from './VotingListChanger'




const ConfirmPlate = ({confirmList, curUserId, changeStatus, maxRole}) => {

    let currentVote = confirmList.filter(elem => elem.user_id == curUserId)[0]

    const [addMode, setAddMode] = useState(false)


    return (
        <div className="confirmation" data-type="mu">
            <div className="confirm_head">
                <h1>Согласование</h1>
                {(maxRole < 3) && <div onClick={() => setAddMode(!addMode)}>{addMode ? 'Отменить' : 'Редактировать'}</div>}
            </div>
            {addMode ? <VotingListChanger /> : <VotingList />}
            {!addMode && confirmList.some(elem => elem.user_id == curUserId) && (
                <div className="actions">
                    <div
                        onClick={() => currentVote.status.id != 3 && changeStatus(3, currentVote.id)}
                        className="btn rej_btn">
                        Отклонить
                    </div>
                    <div
                        onClick={() => currentVote.status.id != 2 && changeStatus(2, currentVote.id)}
                        className="btn confirm_btn">
                        Согласовать
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        confirmList: state.document.confirmList,
        curUserId: state.users.currentUserId,
        maxRole: state.auth.maxRole
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeStatus: (status, voteId) => dispatch({ type: 'CHANGE_CONFIRM', status, voteId })
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPlate)