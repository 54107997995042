import React, {useEffect} from 'react';



const DelDialog = ({delHandler, question, closeHandler}) => {


    useEffect(() => {
        document.addEventListener('click', outsideClick)
        return () => document.removeEventListener('click', outsideClick)
    })

    function outsideClick (e) {
        if (!e.target.closest('.dialog')) closeHandler()
    }


    return (
        <div className="dialog_wrapper">
            <div className="dialog">
                <div className="dialog_title">
                    {question}
                </div>
                <div className='dialog_actions'>
                    <div onClick={() => closeHandler()}>Отменить</div>
                    <div onClick={() => {
                        delHandler()
                        closeHandler()
                    }} className="delete">Удалить</div>
                </div>
            </div>
        </div>
    )
}

export default DelDialog

