import React, { useState } from 'react';


const Password = ({ val, changeHandler, placeholder, id }) => {

    const [type, setType] = useState('password')


    return (
        <div className="password">
            <input type={type} id={id} placeholder={placeholder} value={val} onChange={e => changeHandler(e)} />
            <div onClick={() => type === 'password' ? setType('text') : setType('password')} className="password_icon"></div>
        </div>
    )
}

export default Password