import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCookie, domain } from '../publickFunc'
import { Collapse } from '@material-ui/core'

function getSrc(ext, id, url_file) {
    if (ext == 'doc' || ext == 'xlsx') {
        return `https://view.officeapps.live.com/op/embed.aspx?src=${url_file ? url_file : `${domain}/document/download/${id}`}?api_token=${getCookie("access_token_rg")}`
    } else {
        return `https://docs.google.com/viewer?url=${url_file ? url_file : `${domain}/document/download/${id}`}?api_token=${getCookie("access_token_rg")}&embedded=true`
    }
}


const TextContent = ({ file, loadDoc, doctype, selectedMonth, selectMonth }) => {
    const [withPreload, setPreload] = useState(true)
    const [time, setTime] = useState(null)
    let frame = useRef(null)

    useEffect(() => {
        if (time) clearInterval(time)
        let timer
        let inLoad = true
        setPreload(true)
        if (frame.current && file) {
            frame.current.onload = () => {
                inLoad = false
                setPreload(false)
            }
            timer = setInterval(() => {
                if (inLoad) {
                    frame.current.src = getSrc(file.ext, file.id)
                } else {
                    clearInterval(timer)
                }
            }, 3000)
            setTime(timer)
        }
        return () => clearInterval(timer)

    }, [file])


    return (
        <div className="text_content">
            {doctype === 'rm' ? (
                <ul>
                    <div className={`rm_month_name${selectedMonth === 1 ? ' selected' : ''}`} onClick={() => {
                        if (selectedMonth !== 1) selectMonth(1)
                    }}>1. ВЛ</div>
                    <Collapse in={selectedMonth === 1} timeout="auto">
                        <li><a href="https://yadi.sk/d/rfR8HZYgicAYSQ/1. ВЛ/12-05-001 СМР без опор и провода (км)?w=1" target="_blank">12-05-001 СМР без опор и провода (км)</a></li>
                        <li><a href="https://yadi.sk/d/rfR8HZYgicAYSQ/1. ВЛ/12-05-002 СМР без опор и провода (тн опор)?w=1" target="_blank">12-05-002 СМР без опор и провода (тн опор)</a></li>
                        <li><a href="https://yadi.sk/d/rfR8HZYgicAYSQ/1. ВЛ/12-05-003 Опоры 0,4-750 кВ ( км)?w=1" target="_blank">12-05-003 Опоры 0,4-750 кВ ( км)</a></li>
                        <li><a href="https://yadi.sk/d/rfR8HZYgicAYSQ/1. ВЛ/12-05-004 Опоры 0,4-750кВ?w=1" target="_blank">12-05-004 Опоры 0,4-750кВ</a></li>
                        <li><a href="https://yadi.sk/d/rfR8HZYgicAYSQ/1. ВЛ/12-05-005 Провод?w=1" target="_blank">12-05-005 Провод</a></li>
                        <li><a href="https://yadi.sk/d/rfR8HZYgicAYSQ/1. ВЛ/12-05-006 Грозотрос?w=1" target="_blank">12-05-006 Грозотрос</a></li>
                        <li><a href="https://yadi.sk/d/rfR8HZYgicAYSQ/1. ВЛ/12-05-007 СИП?w=1" target="_blank">12-05-007 СИП</a></li>
                        <li><a href="https://yadi.sk/d/rfR8HZYgicAYSQ/1. ВЛ/12-05-008 Провод повыш.?w=1" target="_blank">12-05-008 Провод повыш.</a></li>
                        <li><a href="https://yadi.sk/d/rfR8HZYgicAYSQ/1. ВЛ/12-05-009 Гирлянды изоляторов 35-750 кВ (лин.изолятор)?w=1" target="_blank">12-05-009 Гирлянды изоляторов 35-750 кВ (лин.изолятор)</a></li>
                        <li><a href="https://yadi.sk/d/rfR8HZYgicAYSQ/1. ВЛ/12-05-010 Устр. защ.от перенапряжений 0,4-35 кВ?w=1" target="_blank">12-05-010 Устр. защ.от перенапряжений 0,4-35 кВ</a></li>
                        <li><a href="https://yadi.sk/d/rfR8HZYgicAYSQ/1. ВЛ/12-05-011 Большие переходы ВЛ 110-750 кВ?w=1" target="_blank">12-05-011 Большие переходы ВЛ 110-750 кВ</a></li>
                        <li><a href="https://yadi.sk/d/rfR8HZYgicAYSQ/1. ВЛ/12-05-012 Переходной пункт ВЛ-КЛ?w=1" target="_blank">12-05-012 Переходной пункт ВЛ-КЛ</a></li>
                        <li><a href="https://yadi.sk/d/rfR8HZYgicAYSQ/1. ВЛ/12-05-013 Защита опор?w=1" target="_blank">12-05-013 Защита опор</a></li>
                        <li><a href="https://yadi.sk/d/rfR8HZYgicAYSQ/1. ВЛ/12-05-014 Лежн?w=1" target="_blank">12-05-014 Лежн</a></li>
                        <li><a href="https://yadi.sk/d/rfR8HZYgicAYSQ/1. ВЛ/12-05-015 Трылевка хлыстов древесины?w=1" target="_blank">12-05-015 Трылевка хлыстов древесины</a></li>
                        <li><a href="https://yadi.sk/d/rfR8HZYgicAYSQ/1. ВЛ/12-05-016 Магистр. газопровод?w=1" target="_blank">12-05-016 Магистр. газопровод</a></li>
                        <li><a href="https://yadi.sk/d/rfR8HZYgicAYSQ/1. ВЛ/12-06-001 ОКГТ?w=1" target="_blank">12-06-001 ОКГТ</a></li>
                        <li><a href="https://yadi.sk/d/rfR8HZYgicAYSQ/1. ВЛ/12-06-002 ОКСН?w=1" target="_blank">12-06-002 ОКСН</a></li>
                        <li><a href="https://yadi.sk/d/rfR8HZYgicAYSQ/1. ВЛ/12-06-003 ВОК?w=1" target="_blank">12-06-003 ВОК</a></li>
                        <li><a href="https://yadi.sk/d/rfR8HZYgicAYSQ/1. ВЛ/12-06-004 ВОК в трубе?w=1" target="_blank">12-06-004 ВОК в трубе</a></li>
                    </Collapse>
                    <div className={`rm_month_name${selectedMonth === 2 ? ' selected' : ''}`} onClick={() => {
                        if (selectedMonth !== 2) selectMonth(2)
                    }}>2. КЛ</div>
                    <Collapse in={selectedMonth === 2} timeout="auto">
                        <li><a href='?'>Папка пуста</a></li>
                    </Collapse>
                    <div className={`rm_month_name${selectedMonth === 3 ? ' selected' : ''}`} onClick={() => {
                        if (selectedMonth !== 3) selectMonth(3)
                    }}>3. ПС</div>
                    <Collapse in={selectedMonth === 3} timeout="auto">
                        <li><a href='https://yadi.sk/d/rfR8HZYgicAYSQ/3. ПС/21-05-005 Выкл. и элем. ПС?w=1' target='_blank'>21-05-005 Выкл. и элем. ПС</a></li>
                        <li><a href='https://yadi.sk/d/rfR8HZYgicAYSQ/3. ПС/21-08-001 Ячейка выкл. НУ 110-750?w=1' target='_blank'>21-08-001 Ячейка выкл. НУ 110-750</a></li>
                        <li><a href='https://yadi.sk/d/rfR8HZYgicAYSQ/3. ПС/21-08-002 Ячейка выкл. НУ 6-15?w=1' target='_blank'>21-08-002 Ячейка выкл. НУ 6-15</a></li>
                        <li><a href='https://yadi.sk/d/rfR8HZYgicAYSQ/3. ПС/21-08-003 Ячейка выкл. КРУ6-15?w=1' target='_blank'>21-08-003 Ячейка выкл. КРУ6-15</a></li>
                        <li><a href='https://yadi.sk/d/rfR8HZYgicAYSQ/3. ПС/21-08-004 Ячейка выкл. ВУ 110-500?w=1' target='_blank'>21-08-004 Ячейка выкл. ВУ 110-500</a></li>
                        <li><a href='https://yadi.sk/d/rfR8HZYgicAYSQ/3. ПС/21-12-001 Здание ОПУ, РЩ 35-750?w=1' target='_blank'>21-12-001 Здание ОПУ, РЩ 35-750</a></li>
                        <li><a href='https://yadi.sk/d/rfR8HZYgicAYSQ/3. ПС/21-12-002 Здания ЗРУ,ЗПС,ОПУ,РЩ?w=1' target='_blank'>21-12-002 Здания ЗРУ,ЗПС,ОПУ,РЩ</a></li>
                    </Collapse>
                </ul>
            ) : (
                    <>
                        <div className="file_container">
                            <div>
                                <label className="btn">
                                    <input type="file" onChange={(e) => {
                                        setPreload(true)
                                        let formData = new FormData()
                                        formData.append('file', e.currentTarget.files[0])
                                        loadDoc(doctype, file.id, formData)
                                    }} />
                            Добавить файл
                        </label>
                            </div>
                        </div>
                        <div className={`watch_file ${(file && withPreload) ? 'preload_comment' : ''}`}>
                            {file && file.file_name && <iframe title="frame" ref={frame} src={getSrc(file.ext, file.id, file.url_file)}></iframe>}
                        </div>
                    </>
                )}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        document: state.document.document,
        file: state.document.file,
        selectedMonth: state.document.selectedRmMonth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadDoc: (doctype, id, formData) => dispatch({ type: 'LOAD_DOC', doctype, id, formData }),
        selectMonth: (month) => dispatch({ type: 'SET_SELECTED_RM_MONTH', month }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TextContent)

