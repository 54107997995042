import React from 'react'
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
} from 'react-router-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './store/reducers/rootreducer'
import createSagaMiddleware from 'redux-saga'
import Login from './components/Login'
import rootSaga from './store/sagas/index'
import Aside from './components/Aside'
import MainContent from './components/MainContent'
import ActionPlate from './components/ActionPlate'
import Notification from './components/Notification'
import MainWrapper from './components/MainWrapper'
import RedirectRoute from './components/RedirectRoute'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const sagaMiddleware = createSagaMiddleware()

export const store = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(sagaMiddleware)),
)

sagaMiddleware.run(rootSaga)

const navList = [
	{ title: 'Объявления', type: 'notif' },
	{ title: 'Заседания', type: 'meetings' },
	{ title: 'Номенклатура', type: 'nomen' },
	{ title: 'МУ НЦС', type: 'muncs' },
	{ title: 'ИД', type: 'ispoldocums' },
	// { title: 'Участники', type: 'users'},
	{ title: 'БЦ', type: 'bc' },
	{ title: 'РМ', type: 'rm' },
	{ title: 'ТЗ', type: 'tzindex' },
]

function App() {
	return (
		<Provider store={store}>
			<Router>
				{/* {store.getState().auth.accessToken && store.getState().users.currentUserId && store.getState().notif.text && <Notification />} */}
				<div id='App' className={`role_${store.getState().auth.maxRole}`}>
					<RedirectRoute />
					<Route
						path={[
							'/nomen',
							'/ispoldocums',
							'/tzindex',
							'/muncs',
							'/rm',
							'/bc',
							'/notif',
							'/meetings',
							'/users',
						]}
						children={({ match }) => {
							if (!match && !window.location.pathname.match('/login'))
								return <Redirect to='/nomen' />
							if (
								window.location.pathname.match('/login') ||
								!store.getState().auth.accessToken ||
								!store.getState().users.currentUserId
							) {
								return <Redirect to='/login' />
							} else {
								let typeLoc = window.location.pathname.replace('/', '')
								return (
									<MainWrapper>
										<Aside elems={navList} typeLoc={typeLoc} />
										<MainContent typeLoc={typeLoc} />
										<ActionPlate />
									</MainWrapper>
								)
							}
						}}
					/>
					<Route
						path='/login'
						children={({ match }) => {
							if (!match && window.location.pathname.match('/login')) {
								return <Redirect to='/login' />
							} else if (match) {
								return <Login />
							} else {
								return null
							}
						}}
					/>
				</div>
			</Router>
		</Provider>
	)
}

export default App
