import React from 'react'
import { connect } from 'react-redux'
import UserPlateList from './UserPlateList'
import UserForm from './UserForm'
import OrgInfo from './OrgInfo'


const UsersPlate = ({ isUsers }) => {
    return (
        <div className="users_plate">
            <UserPlateList />
            {isUsers ? <UserForm /> : <OrgInfo />}
        </div>
    )
}


const mapStateToProps = state => {
    return {
        isUsers: state.userplate.isUsers
    }
}

export default connect(mapStateToProps)(UsersPlate)