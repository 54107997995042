import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const NotifSettings = ({ setSettingMode, setSettingId, id, groupVotings, addVoting, removeVoting, pos }) => {

    const [arr, setArr] = useState([])

    useEffect(() => {
        const res = [
            ...(new Array(9)).fill(0).map((elem, ind) => !!groupVotings.find(elem => elem.group_id == id && elem.advert_voting_type_id == (ind+1)))
            // !!groupVotings.find(elem => elem.group_id == id && elem.advert_voting_type_id == 1),
            // !!groupVotings.find(elem => elem.group_id == id && elem.advert_voting_type_id == 2),
            // !!groupVotings.find(elem => elem.group_id == id && elem.advert_voting_type_id == 3),
            // !!groupVotings.find(elem => elem.group_id == id && elem.advert_voting_type_id == 4),
            // !!groupVotings.find(elem => elem.group_id == id && elem.advert_voting_type_id == 5),
            // !!groupVotings.find(elem => elem.group_id == id && elem.advert_voting_type_id == 6),
            // !!groupVotings.find(elem => elem.group_id == id && elem.advert_voting_type_id == 7),
            // !!groupVotings.find(elem => elem.group_id == id && elem.advert_voting_type_id == 8),
            // !!groupVotings.find(elem => elem.group_id == id && elem.advert_voting_type_id == 9)
        ]
        setArr(res)
    }, [id, groupVotings])

    useEffect(() => {
        document.addEventListener('click', outside)
        return () => document.removeEventListener('click', outside)
    })

    function outside(e) {
        if (!e.target.closest('.notif_settings')) {
            setSettingMode()
            setSettingId()
        }
    }

    function changeHandler(e, votingType, groupId, item) {
        if (e.currentTarget.checked) {
            addVoting(votingType, groupId, item)
        } else {
            removeVoting(votingType, groupId, item)
        }
    }

    return (
        <div className="notif_settings" style={{top: pos == 'bottom' ? '60px' : 'initial', bottom: pos == 'top' ? '60px' : 'initial'}}>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>0.4 - 20</th>
                        <th>35 - 220</th>
                        <th>330 - 1150</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>ВЛ</td>
                        <td><input onChange={e => { changeHandler(e, 1, id, '0,4-20') }} type="checkbox" checked={arr[0]} /></td>
                        <td><input onChange={e => { changeHandler(e, 2, id, '35-220') }} type="checkbox" checked={arr[1]} /></td>
                        <td><input onChange={e => { changeHandler(e, 3, id, '330-1150') }} type="checkbox" checked={arr[2]} /></td>
                    </tr>
                    <tr>
                        <td>КЛ</td>
                        <td><input onChange={e => { changeHandler(e, 4, id, '0,4-20') }} type="checkbox" checked={arr[3]} /></td>
                        <td><input onChange={e => { changeHandler(e, 5, id, '35-220') }} type="checkbox" checked={arr[4]} /></td>
                        <td><input onChange={e => { changeHandler(e, 6, id, '330-1150') }} type="checkbox" checked={arr[5]} /></td>
                    </tr>
                    <tr>
                        <td>ПС</td>
                        <td><input onChange={e => { changeHandler(e, 7, id, '0,4-20') }} type="checkbox" checked={arr[6]} /></td>
                        <td><input onChange={e => { changeHandler(e, 8, id, '35-220') }} type="checkbox" checked={arr[7]} /></td>
                        <td><input onChange={e => { changeHandler(e, 9, id, '330-1150') }} type="checkbox" checked={arr[8]} /></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        id: state.notif.settingId,
        groupVotings: state.ispoldocums.groupVotings,
        pos: state.notif.settingPos
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSettingMode: () => dispatch({ type: 'SET_SETTING_MODE', mode: false }),
        setSettingId: () => dispatch({ type: 'SET_SETTING_ID', settingId: null }),
        addVoting: (votingType, groupId, item) => dispatch({ type: 'ADD_GROUP_VOTING', votingType, groupId, item }),
        removeVoting: (votingType, groupId, item) => dispatch({ type: 'REMOVE_GROUP_VOTING', votingType, groupId, item })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotifSettings)