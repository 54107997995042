import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import TextContent from './TextContent'
import CommentPlate from './CommentPlate'
import ConfirmPlate from './ConfirmPlate'
import { connect } from 'react-redux'
import IdTable from './IdTable'
import Search from './Search'
import OfferPlate from './OfferPlate'
import {
	fetchData,
	titleInfo,
	notTextContentArr,
	notConfirmArr,
	domain,
} from '../publickFunc'
import NotifPlate from './NotifPlate'
import MeetingContent from './MeetingContent'
import NotifMenu from './NotifMenu'
import DefaultMenu from './DefaultMenu'
import NotifTable from './NotifTable'
import UsersPlate from './UsersPlate'
import FactoriesPlate from './FactoriesPlate'
import { contextType } from 'react-scrollbar'
import MeetingsPlate from './MeetingsPlate'
import MeetingsActivePlate from './MeetingsActivePlate'
import FactoriesContent from './FactoriesContent'

export const Context = React.createContext()

const MainContent = ({
	typeLoc,
	fetchDoc,
	file,
	currId,
	auth,
	updateUserInfo,
	notifList,
	notifDoc,
	setNotifDoc,
	notifType,
	selectedRmMonth,
}) => {
	const [typeOfPlate, setType] = useState('')
	const [showNotif, setShow] = useState(false)
	const history = useHistory()

	useEffect(() => {
		if ((notifDoc || typeLoc) !== 'users') fetchDoc(notifDoc || typeLoc)
	}, [typeLoc, notifDoc, fetchDoc, selectedRmMonth])

	useEffect(() => {
		setType(notifType)
	}, [notifType])

	useEffect(() => {
		if (notifDoc) history.push(`/${notifDoc}`)
		setTimeout(() => setNotifDoc(null), 0)
	}, [notifDoc])

	useEffect(() => {
		if (typeLoc === 'meetings') {
			setType('no-meetings')
		} else if (typeLoc === 'bc') {
			setType('no-factories')
		} else {
			setType('confirm')
		}
	}, [typeLoc])

	useEffect(() => {
		if (currId) {
			let res = fetchData(`/users/${currId}`, res => {
				let maxRole = res.roles.map(elem => elem.id).sort()
				maxRole = maxRole[0]
				let name = `${res.sname} ${res.name[0]}.${res.lname[0]}.`
				if (
					auth.email != res.email ||
					auth.userName != name ||
					auth.maxRole != maxRole ||
					auth.userGroupId != res.group_id
				) {
					document.cookie = `email=${res.email}; path=/; expires=1584200266`
					document.cookie = `user_name=${name}.; path=/; expires=1584200266`
					document.cookie = `max_role=${maxRole}; path=/; expires=1584200266`
					document.cookie = `group_id=${res.group_id}; path=/; expires=1584200266`
					updateUserInfo(res.email, name, maxRole, res.group_id)
				}
			})
		}
	}, [currId])

	useEffect(() => document.addEventListener('click', outsideClicK))

	const outsideClicK = e => {
		if (
			showNotif &&
			(!e.target.closest('.notif_btn') || e.target.closest('.notif_body'))
		) {
			setShow(false)
		}
	}

	return (
		<Context.Provider value={{ typeOfPlate, setType }}>
			<main>
				<header>
					<div className='title'>
						<h1>{titleInfo[notifDoc || typeLoc].title}</h1>
						<h2>{titleInfo[notifDoc || typeLoc].gist}</h2>
					</div>
					{(notifDoc || typeLoc) !== 'users' && (
						<div className='menu'>
							{(notifDoc || typeLoc) === 'ispoldocums' ? <Search /> : null}
							{(notifDoc || typeLoc) !== 'notif' && (
								<div
									onClick={() => {
										if (notifList.length > 0) setShow(true)
									}}
									className={`notif_btn ${
										notifList.filter(elem => !elem.read_at).length > 0 &&
										'with_notif'
									} ${showNotif ? 'open_notif' : ''}`}>
									<NotifPlate notifClass={showNotif ? 'visible_notif' : ''} />
								</div>
							)}
							{(notifDoc || typeLoc) != 'notif' ? (
								<DefaultMenu
									typeOfPlate={typeOfPlate}
									setType={setType}
									typeLoc={typeLoc}
								/>
							) : (
								<NotifMenu />
							)}
							{(notifDoc || typeLoc) !== 'ispoldocums' &&
								(notifDoc || typeLoc) !== 'notif' &&
								(notifDoc || typeLoc) !== 'rm' &&
								(notifDoc || typeLoc) !== 'bc' &&
								(notifDoc || typeLoc) !== 'meetings' && (
									<a
										target='_blank'
										href={
											typeLoc === 'rm'
												? 'https://drive.google.com/drive/folders/1DeMayvfR4NLM5_sUTf20DTkHKUmDVVRP'
												: file &&
												  (file.url_file
														? file.url_file
														: `${domain}/document/download/${file.id}`)
										}
										className={`btn load_btn${file ? '' : ' in_load'}`}>
										Скачать
									</a>
								)}
						</div>
					)}
				</header>
				<div className='content'>
					<div>
						{(notifDoc || typeLoc) === 'users' && <UsersPlate />}
						{(notifDoc || typeLoc) === 'notif' && <NotifTable />}
						{(notifDoc || typeLoc) === 'ispoldocums' && (
							<IdTable isConfirm={typeOfPlate === 'confirm'} />
						)}
						{(notifDoc || typeLoc) === 'meetings' && (
							<MeetingContent setType={setType} typeOfPlate={typeOfPlate} />
						)}
						{(notifDoc || typeLoc) === 'bc' && (
							<FactoriesContent setType={setType} typeOfPlate={typeOfPlate} />
						)}
						{!notTextContentArr.includes(notifDoc) &&
							!notTextContentArr.includes(typeLoc) && (
								<TextContent
									doctype={typeLoc}
									title={titleInfo[typeLoc].title}
								/>
							)}
						{typeOfPlate === 'confirm' &&
							!notConfirmArr.includes(notifDoc) &&
							!notConfirmArr.includes(typeLoc) && <ConfirmPlate />}
						{typeOfPlate === 'comment' && <CommentPlate />}
						{typeOfPlate === 'factories' && <FactoriesPlate setType={setType} />}
						{typeOfPlate === 'offer' && <OfferPlate />}
						{(typeOfPlate === 'meetings' && auth.maxRole < 3 && (
							<MeetingsPlate setType={setType} />
						)) ||
							(typeOfPlate === 'active-meeting' && auth.maxRole < 3 && (
								<MeetingsActivePlate setType={setType} />
							)) ||
							(typeOfPlate === 'no-meetings' && (
								<div className='no-select-plate'>Выберите заседание</div>
							)) ||
							(typeOfPlate === 'no-factories' && (
								<div className='no-select-plate'>Выберите завод</div>
							))}
					</div>
				</div>
			</main>
		</Context.Provider>
	)
}

const mapStateToProps = state => {
	return {
		file: state.document.file,
		currId: state.users.currentUserId,
		auth: state.auth,
		notifList: state.notif.notifList,
		notifDoc: state.notif.notifDoc,
		notifType: state.notif.notifType,
		selectedRmMonth: state.document.selectedRmMonth,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		fetchDoc: doctype => dispatch({ type: 'FETCH_DOC', doctype }),
		updateUserInfo: (email, userName, maxRole, userGroupId) =>
			dispatch({
				type: 'UPDATE_USER_INFO',
				email,
				userName,
				maxRole,
				userGroupId,
			}),
		setNotifDoc: notifDoc => dispatch({ type: 'SET_NOTIF_DOC', notifDoc }),
		setNotifType: notifType => dispatch({ type: 'SET_NOTIF_TYPE', notifType }),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MainContent)
