import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';


const Notification = ({ text, close }) => {

    const [dontShow, setDontShow] = useState(false)

    function addCookie() {
        document.cookie = `watched=true; path=/; expires=1584200266`
    }

    function closeHandler(dontShow) {
        if (dontShow) {
            addCookie()
        }
        close()
    }

    return (
        <div className="notif_wrapper" style={{display: text ? 'block' : 'none'}}>
            <div className="notif_block">
                <div onClick={() => closeHandler(dontShow)} className="close_btn"></div>
                <h1>Новое объявление!</h1>
                <div className="notif_text">{text}</div>
                <Link onClick={() => {
                    close()
                }} to="/notif">Ознакомиться</Link>
                <div className="dont_show">
                    <input checked={dontShow} onChange={() => setDontShow(!dontShow)}  id="notif_check" type="checkbox" />
                    <label htmlFor="notif_check">Больше не показывать</label>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        text: state.notif.text
    }
}

const mapDispatchToProps = dispatch => {
    return {
        close: () => dispatch({type: 'SET_NOTIF', text: null})
        
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Notification)