import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';



const FipChanger = ({id, fip, visible, orgList, clear, bottom, saveFipChange, deleteFip }) => {

    const [openOrg, setOpenOrg] = useState(false),
          [filterVal, setFilterVal] = useState(''),
          [org, setOrg] = useState(fip.group_id),
          [title, setTitle] = useState(fip.fip),
          [name, setName] = useState(fip.name)



    useEffect(() => {
        setTitle(fip.fip)
        setName(fip.name)
        setOrg(fip.group_id)
    }, [id])      
    useEffect(() => {
        document.addEventListener('click', outsideClick)
        return () => document.removeEventListener('click', outsideClick)
    })

    function outsideClick(e) {
        if (!e.target.closest('.fip_changer')) clear()
    }

    return (
        <div style={{bottom}} className={`fip_changer ${visible ? 'visible_changer' : ''}`}>
            <div>
                <input onChange={e => setTitle(e.currentTarget.value)} value={title} type="text" placeholder="Ид (код САП)" />
            </div>
            <div>
                <div onClick={(e) => {
                    setOpenOrg(!openOrg)
                }} className="selected_org">{org ? org.title : 'Организация'}</div>
                <div className={`org_list ${openOrg ? 'visible_org_list' : ''}`}>
                    <div className="search">
                        <input placeholder="Поиск" defaultValue={filterVal} onChange={e => setFilterVal(e.currentTarget.value)} />
                    </div>
                    {orgList && orgList.filter(elem => elem.title.toLowerCase().match(filterVal.toLowerCase())).map(elem => {
                        return (
                            <div key={elem.id} onClick={() => {
                                setOrg(elem)
                                setOpenOrg(false)
                            }}>{elem.title}</div>
                        )
                    })}
                </div>
            </div>
            <div>
                <input onChange={e => setName(e.currentTarget.value)} value={name} type="text" placeholder="Наименование объекта" />
            </div>
            <div className="actions">
                <div onClick={() => deleteFip()} className="btn del">Удалить Фип</div>
                <div onClick={() => saveFipChange(title, name, org)} className="btn save">Сохранить</div>
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        docums: state.ispoldocums.docs,
        orgList: state.ispoldocums.orgs,
        fip: state.fipchange.fip,
        bottom: state.fipchange.bottom,
        id: state.fipchange.id
    }
}

const mapDipatchToProps = dispatch => {
    return {
        clear: () => dispatch({type: 'CLEAR_FIP_CHANGE'}),
        saveFipChange: (fip, name, group_id) => dispatch({type: 'SAVE_FIP_CHANGE', fip, name, group_id}),
        deleteFip: () => dispatch({type: 'DELETE_FIP'})
    }
}


export default connect(mapStateToProps, mapDipatchToProps)(FipChanger)