import React, { useState, useEffect } from 'react';


const AsideRef = ({ }) => {
    const [opened, setOpened] = useState(false)

    useEffect(() => {
        document.addEventListener('click', outsideRefClick)
        return () => document.removeEventListener('click', outsideRefClick)
    })

    function outsideRefClick(e) {
        if (opened && !e.target.closest('.ref_content')) setOpened(false)
    }

    return (
        <div className={`reference ${opened ? 'opened_ref' : ''}`}>
            <div onClick={() => setOpened(true)} className="ref_btn">Справка</div>
            <div className="ref_content">
                {/* <div className="ref_nav">
                    <div className="ref_title">№314</div>
                    <div className="ref_arrow"></div>
                </div>
                <div>Бюллетень цен</div>
                <div>Приказ №10</div> */}
                <div>
                    <a target="_blank" href="https://storage.yandexcloud.net/rgceo/files/1/%D0%98%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D1%8F%20%D0%A0%D0%93-%D0%A6%D0%AD%D0%9E.xlsx">
                        Инструкция
                    </a>
                </div>
            </div>
        </div>
    )
}


export default AsideRef