import React, { useState, useEffect } from 'react'
import Collapse from '@material-ui/core/Collapse'
import { connect } from 'react-redux'
import { formatDate, formatTime } from '../publickFunc'

const MeetingElemUpcoming = ({
	meet,
	setActiveMeeting,
	fetchDoc,
	activeMeeting,
	completeMeeting,
	maxRole,
	typeOfPlate,
	setType,
}) => {
	const [opened, setOpened] = useState(false)
	const [agenda, setAgenda] = useState([])

	useEffect(() => {
		if (activeMeeting && activeMeeting.files.length > 0) {
			const uploadAgenda = activeMeeting.files
				.filter(elem => elem.type === 'agenda')
				.map(i => i)
			setAgenda(uploadAgenda)
		} else {
			setAgenda([undefined])
		}
	}, [activeMeeting])

	useEffect(() => {
		if (activeMeeting && activeMeeting.id === meet.id) {
			setOpened(true)
			fetchDoc('meetings')
		} else {
			setOpened(false)
		}
	}, [activeMeeting])

	return (
		<div className='meeting_elem'>
			<div
				className='meeting_head'
				onClick={() => {
					setActiveMeeting(meet.id, typeOfPlate)
					if (activeMeeting !== null) setType('active-meeting')
				}}>
				<div>
					<div className='meeting_name'>{meet.title}</div>
					<div className='meeting_start'>
						{meet.date && <span className='date'>{formatDate(meet.date)}</span>}
						{meet.time && (
							<span className='time'>
								Начало <span>{formatTime(`${meet.date}T${meet.time}`)}</span>
							</span>
						)}
					</div>
				</div>
				<div className={`meeting_arrow ${opened && 'rotated'}`}></div>
			</div>
			<Collapse in={opened} timeout='auto' unmountOnExit>
				{activeMeeting && agenda.length > 0 && agenda[0] && (
					<div className='meeting-file-list'>
						{agenda.map(
							elem =>
								elem && (
									<div key={elem.id} className='meeting-file'>
										<h5 className='file-title'>Повестка</h5>
										<a
											href={elem.url}
											download={`${elem.name}.${elem.ext}`}
											className='meeting-file-download'>
											<span>{`${elem.name}.${elem.ext}`}</span>
										</a>
									</div>
								),
						)}
					</div>
				)}
				{maxRole < 3 && (
					<span
						className='meeting_end'
						onClick={() => completeMeeting(meet.id, { completed: 1 })}>
						Завершить заседание
					</span>
				)}
			</Collapse>
		</div>
	)
}

const mapStateToProps = state => ({
	activeMeeting: state.meetings.activeMeeting,
	maxRole: state.auth.maxRole,
})

const mapDispatchToProps = dispatch => ({
	setActiveMeeting: (id, typeOfPlate) =>
		dispatch({ type: 'SELECT_MEETING', id, typeOfPlate }),
	fetchDoc: doctype => dispatch({ type: 'FETCH_DOC', doctype }),
	completeMeeting: (id, completed) =>
		dispatch({ type: 'COMPLETE_MEETING', id, completed }),
	getIssues: id => dispatch({ type: 'GET_ISSUES', id }),
})

export default connect(mapStateToProps, mapDispatchToProps)(MeetingElemUpcoming)
