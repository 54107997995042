import React from 'react';
import { connect } from 'react-redux';



const UserListElem = ({ user, companies, setCurrentUser, currentUser, getUserSubs, maxRole, currentUserId }) => {

    let userCompanies = user.company_ids ? companies.filter(comp => user.company_ids.includes(comp.id)).map(comp => comp.name).join(', ') : ''

    return (
        <div
            onClick={() => {
                setCurrentUser(user)
                if ((maxRole < 3 && user.roles[0].id > 2) || user.id == currentUserId ) getUserSubs(user.id)
            }}
            key={user.id}
            className={`user_plate_elem ${currentUser && (currentUser.id == user.id) ? 'current' : ''}`}
        >
            <div className="user_name">{user.sname} {user.name}</div>
            <div title={userCompanies} className="user_org">{userCompanies}</div>
        </div>
    )
}


const stateToProps = state => {
    return {
        orgs: state.ispoldocums.orgs,
        companies: state.ispoldocums.companies,
        currentUser: state.userplate.currentUser,
        maxRole: state.auth.maxRole,
        currentUserId: state.users.currentUserId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentUser: currentUser => dispatch({ type: 'SET_CURRENT_USER', currentUser }),
        getUserSubs: userId => dispatch({ type: 'GET_USER_SUBS', userId })
    }
}


export default connect(stateToProps, mapDispatchToProps)(UserListElem)