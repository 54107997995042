import React from 'react';
import { connect } from 'react-redux';
import SubField from './SubField';





const MainWrapper = ({ text, children, subMode }) => {

    return (
        <div 
        style={{ width: '100%', height: '100%', display: 'flex' }} 
        // className={`${text ? 'filter_blur' : ''}`}
        >
            {subMode && <SubField/>}
            {children}
        </div>
    )
}


const mapStateToProps = state => {
    return {
        text: state.notif.text,
        subMode: state.subscribe.subMode
    }
}

export default connect(mapStateToProps)(MainWrapper)