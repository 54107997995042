import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom'


const RedirectRoute = ({ accessToken, currentUserId }) => {
    return (
        <Route exact path="/" render={() => {
            if ((!accessToken || !currentUserId)) {
                return <Redirect to="/login" />
            } else {
                return <Redirect to="/nomen" />
            }
        }} />
    )
}

const mapStateToProps = state => {
    return {
        accessToken: state.auth.accessToken,
        currentUserId: state.users.currentUserId
    }
}

export default connect(mapStateToProps)(RedirectRoute)