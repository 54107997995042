import React from 'react';
import { connect } from 'react-redux';
import VotingElem from './VotingElem'
import ScrollArea from 'react-scrollbar'


const VotingList = ({ confirmList, speed }) => {

    return (
        <div className="custom_list voting_list">
            <div className="col_titles">
                <div>Сотрудник</div>
                <div>Статус</div>
            </div>
            <ScrollArea
                speed={speed}
                horizontal={false}
                vertical={true}
                className="confirm_list"
                smoothScrolling={true}
                verticalContainerStyle={{ width: 4 }}
                verticalScrollbarStyle={{ width: 4, marginLeft: 0 }}
                stopScrollPropagation={true}
            >
                {confirmList.map(elem => <VotingElem key={elem.id} data={elem} />)}
            </ScrollArea>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        confirmList: state.document.confirmList,
        curUserId: state.users.currentUserId,
        speed: state.auth.speed
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeStatus: (status, voteId) => dispatch({ type: 'CHANGE_CONFIRM', status, voteId })
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(VotingList)
