import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import ScrollArea from 'react-scrollbar'
import VotingChangeElem from './VotingChangeElem'

const OrgInfo = ({ currentComp, userList, speed, isNewOrg, maxRole, denyOrg, createOrg, changeOrgInfo, changeUserCompIds, setCurrentUser, setType, currentUserId, getUserSubs }) => {

    const [orgName, setName] = useState('')
    const [wasChanged, setChanged] = useState(false)
    const [editMode, setMode] = useState(false)

    useEffect(() => {
        if (currentComp && !isNewOrg) {
            setName(currentComp.name)
        }
    }, [currentComp, isNewOrg])

    useEffect(() => {
        if (isNewOrg) {
            setName('')
        }
    }, [isNewOrg])

    function changeOrg(value, changer) {
        if (!isNewOrg && !wasChanged) {
            setChanged(true)
        }
        changer(value)
    }

    const usersOfComp = useMemo(() => userList.filter(elem => (elem.company_ids && elem.company_ids.includes(currentComp.id))), [userList, currentComp])

    return (
        <div className="user_plate_content org_info">
            <div className="user_form_head">
                <div className="user_plate_avatar"></div>
                <div className="user_form_name">{isNewOrg ? 'Новая организация' : currentComp ? currentComp.name : ''}</div>
                {!isNewOrg && <div className="org_counter">{currentComp && usersOfComp.length}</div>}
            </div>
            <h1>Основная информация</h1>
            <h2>Наименование</h2>
            <div>
                <input
                    onChange={e => changeOrg(e.currentTarget.value, setName)}
                    disabled={true}
                    type="text" value={orgName} />
            </div>
            {!isNewOrg && (
                <div className="confirm_head">
                    <h2>Участники</h2>
                    {(maxRole < 3) && <div onClick={() => setMode(!editMode)}>{!editMode ? 'Редактировать' : 'Отменить'}</div>}
                </div>
            )}
            {!isNewOrg && (
                <div className="custom_list">
                    <div className="col_titles">
                        <div>Сотрудник</div>
                        <div>Роль</div>
                    </div>
                    <ScrollArea
                        speed={speed}
                        horizontal={false}
                        vertical={true}
                        className="confirm_list"
                        smoothScrolling={true}
                        verticalContainerStyle={{ width: 4 }}
                        verticalScrollbarStyle={{ width: 4, marginLeft: 0 }}
                        stopScrollPropagation={true}
                    >
                        {!editMode ? usersOfComp.sort((a, b) => a.roles[0].id - b.roles[0].id).map(user => (
                            <div onClick={() => {
                                if (maxRole < 3) {
                                    setCurrentUser(user)
                                    if ((maxRole < 3 && user.roles[0].id > 2) || user.id == currentUserId) getUserSubs(user.id)
                                    setType(true)
                                }
                            }} key={user.id} className="confirm_item soglasovano">
                                <div className="confirm_user">{`${user.sname} ${user.name[0] ? user.name[0] + '.' : ''}`}</div>
                                <div className="confirm_status">{user.roles[0] && user.roles[0].name}</div>
                            </div>
                        )) : (
                                userList.map(user => {
                                    let inList = usersOfComp.find(elem => elem.id == user.id)
                                    return <VotingChangeElem
                                        key={user.id}
                                        status={'soglasovano'}
                                        inVoting={inList ? true : false}
                                        userName={`${user.sname} ${user.name[0]}.`}
                                        addVoter={() => changeUserCompIds(user.id, currentComp.id, 'add')}
                                        removeVoter={() => changeUserCompIds(user.id, currentComp.id, 'remove')}
                                    />
                                })
                            )}
                    </ScrollArea>
                </div>
            )}
            {((maxRole < 3) || isNewOrg) && (
                <div className="org_actions">
                    {isNewOrg && <div onClick={() => denyOrg()} className="rej_btn">Отменить</div>}
                    <div
                        onClick={() => {
                            if (isNewOrg) createOrg(orgName)
                            else changeOrgInfo(currentComp.id, orgName)
                        }}
                        className={`btn org_btn ${(isNewOrg && orgName) || (!isNewOrg && wasChanged) ? '' : 'passive'}`}>
                        {isNewOrg ? 'Добавить' : 'Сохранить'}
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentComp: state.userplate.currentComp,
        userList: state.users.userList,
        speed: state.auth.speed,
        isNewOrg: state.userplate.isNewOrg,
        maxRole: state.auth.maxRole,
        currentUserId: state.users.currentUserId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        denyOrg: () => dispatch({ type: 'ADD_NEW_ORG_MODE', isNew: false }),
        createOrg: title => dispatch({ type: 'CREATE_ORG', title }),
        changeOrgInfo: (id, title) => dispatch({ type: 'CHANGE_ORG', id, title }),
        changeUserCompIds: (userId, compId, typeOfAction) => dispatch({ type: 'CHANGE_USER_COMPANIES', userId, compId, typeOfAction }),
        setCurrentUser: currentUser => dispatch({ type: 'SET_CURRENT_USER', currentUser }),
        setType: isUsers => dispatch({ type: 'SET_LIST_TYPE', isUsers }),
        getUserSubs: userId => dispatch({ type: 'GET_USER_SUBS', userId })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgInfo)