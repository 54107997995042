import React, { useState } from 'react';
import FileElem from './FileElem'
import { connect } from 'react-redux';

const AddOfer = ({createOffer}) => {

    const [name, setName] = useState('')
    const [gist, setGist] = useState('')
    const [checkbox, setCheckbox] = useState(false)
    const [idarr, setIdarr] = useState([null, null])
    const [tkparr, setTkparr] = useState([null, null, null])
    const [prelaoder, setPreloader] = useState(false)


    function addOfferHandler (name, gist, checkbox, tkparr, idarr) {
       if (name && (checkbox || tkparr.filter(elem => elem).length == 3 || idarr.filter(elem => elem).length == 2)) {
           let typeFiles = checkbox ? 'empty' : tkparr.filter(elem => elem).length == 3 ? 'tkp' : 'id',
               title = name,
               description = gist,
               file = typeFiles == 'tkp' ? tkparr : idarr
            createOffer(typeFiles, title, description, file)
       }
    }

    return (
        <div className="add_offer_plate">
            <div className="name_input">
                <input onChange={e => setName(e.currentTarget.value)} placeholder="Наименование" value={name} />
            </div>
            <div className="gist_area">
                <textarea placeholder="Описание" value={gist} onChange={e => setGist(e.currentTarget.value)} />
            </div>
            <div className="file_inputs_title">
                <div>Обоснования</div>
                <div className="custom-checkbox">
                    <input checked={checkbox} onChange={() => setCheckbox(!checkbox)} type="checkbox" id='check' />
                    <label htmlFor="check" >Отсутствуют</label>
                </div>
            </div>
            {(!checkbox && tkparr.filter(elem => elem).length == 0) && (
                <div className="file_inputs id_files">
                    <div className="type_name">ИД</div>
                    <div className="files">
                        <FileElem arr={idarr} setFunc={setIdarr} ind={0} />
                        <FileElem arr={idarr} setFunc={setIdarr} ind={1} />
                    </div>
                </div>
            )}
            {(!checkbox && idarr.filter(elem => elem).length == 0) && (
                <div className="file_inputs">
                    <div className="type_name">ТКП</div>
                    <div className="files">
                        <FileElem arr={tkparr} setFunc={setTkparr} ind={0} />
                        <FileElem arr={tkparr} setFunc={setTkparr} ind={1} />
                        <FileElem arr={tkparr} setFunc={setTkparr} ind={2} />
                    </div>
                </div>
            )}
            <div className="action">
                <div
                    onClick={e => {
                        setPreloader(true)
                        addOfferHandler(name, gist, checkbox, tkparr, idarr)
                    }}
                    className={`${(name && gist && (checkbox || tkparr.filter(elem => elem).length == 3 || idarr.filter(elem => elem).length == 2)) ? 'btn' : 'passive'} ${prelaoder && 'preload_comment'}`}
                >Готово</div>
            </div>
        </div>
    )
}


const mapDispatchToProps = dispatch => {
    return {
        createOffer: (typeFiles, title, description, file) => dispatch({type: 'CREATE_OFFER', typeFiles, title, description, file})
    }
}

export default connect(null, mapDispatchToProps)(AddOfer)