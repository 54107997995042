import React, { useState, useRef, useEffect, useCallback } from 'react';
import ScrollArea from 'react-scrollbar'
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip'



const SmartSelectMulty = ({ initVal = [], list, keyOfList, selectFunc, speed, isDisabled, placeholder }) => {
    const [selected, setSelected] = useState(initVal || [])
    const [filter, setFilter] = useState('')
    const [filterMode, setMode] = useState(false)

    const input = useRef(null)

    useEffect(() => {
        setSelected(initVal)
    }, [initVal])

    useEffect(() => {
        document.addEventListener('click', outside)
        return () => document.removeEventListener('click', outside)
    })

    function clickHandler(val) {
        setFilter('')
        let clone = selected.slice()
        let index = clone.findIndex(elem => elem[keyOfList] === val[keyOfList])
        if (index !== -1) {
            clone.splice(clone.indexOf(val), 1)
        } else {
            clone.push(val)
        }
        if (selectFunc) selectFunc(clone)
    }

    function arrowClick() {
        if (input.current) input.current.focus()
    }

    function outside(e) {
        if (filterMode && !e.target.closest('.smart_select')) {
            setMode(false)
            setFilter('')
        }
    }

    const getText = useCallback(() => {
        return ((selected && selected.length > 0) ? (selected.length > 1 ? `Несколько (${selected.length})` : selected[0][keyOfList]) : '')
    }, [selected, keyOfList])



    return (
        <div className="smart_select smart_select_multy">
            <Tooltip
                title={(selected.length > 0 && selected.map(elem => elem[keyOfList]).join(', ')) || ''}
                enterDelay={1000}
                enterNextDelay={1000}
                placement="top"
            >
                <input
                    ref={input}
                    value={filterMode ? filter : getText()}
                    onChange={e => setFilter(e.currentTarget.value)}
                    onFocus={e => {
                        setMode(true)
                    }}
                    disabled={isDisabled}
                    placeholder={(selected && selected.length > 0) ? getText() : ''}
                    style={{ borderBottomLeftRadius: filterMode ? 0 : 10 }}
                />
            </Tooltip>
            <div
                onClick={() => arrowClick()}
                style={{ borderBottomRightRadius: filterMode ? 0 : 10 }}
                className="arrows"></div>
            <ScrollArea
                speed={speed}
                horizontal={false}
                vertical={true}
                className={`select_list ${filterMode ? 'opened_select' : ''}`}
                style={{ height: 208 }}
                smoothScrolling={true}
                verticalContainerStyle={{ width: 4 }}
                verticalScrollbarStyle={{ width: 4, marginLeft: 0 }}
                stopScrollPropagation={true}
            >
                {list.filter(elem => elem[keyOfList].toLowerCase().match(filter.toLocaleLowerCase()))
                    .map((elem, ind) => (
                        <div
                            key={`${elem[keyOfList]}_${ind}`}
                            onClick={() => clickHandler(elem)}
                            className={`select_list_item ${selected.some(item => item[keyOfList] === elem[keyOfList]) ? 'selected_item' : ''}`}
                        >
                            {elem[keyOfList]}
                        </div>
                    ))}
            </ScrollArea>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        speed: state.auth.speed
    }
}

export default connect(mapStateToProps)(SmartSelectMulty)