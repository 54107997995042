import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';



const Search = ({ search, closeSearch, autoComplete, setAutocomplete, searchValue }) => {
    const [value, setValue] = useState('')
    const [timer, setTimer] = useState(null)
    const [showAutocompl, setShow] = useState(false)


    let outsideClick = useCallback((e) => {
        if (showAutocompl && !e.target.closest('.search')) setShow(false)
    }, [showAutocompl])

    useEffect(() => {
        setValue(searchValue)
    }, [searchValue])

    // useEffect(() => {
    //     document.addEventListener('click', outsideClick)
    //     return () => document.removeEventListener('click', outsideClick)
    // })

    useEffect(() => {
        let searchArr = window.location.search.split('?find=')
        if (searchArr.length === 2) {
            let decoded = decodeURI(searchArr[1])
            setValue(decoded)
            search(decoded, 0)
        }
    }, [search])

    useEffect(() => {
        document.addEventListener('click', outsideClick)
        return () => document.removeEventListener('click', outsideClick)
    }, [showAutocompl, outsideClick])

    function changeHandler(targetVal) {
        if (timer) clearTimeout(timer)
        setValue(targetVal)
        if (targetVal == '') {
            closeSearch()
        } else {
            const curTimer = setTimeout(() => updateAutocomplete(targetVal), 1500)
            setTimer(curTimer)
        }

    }

    function updateAutocomplete(targetVal) {
        search(targetVal, 0)
        if (autoComplete.includes(targetVal)) return
        let clone = autoComplete.slice()
        if (clone.length == 3) clone.shift()

        clone.push(targetVal)
        setAutocomplete(clone)
    }

    // let filtered = autoComplete.filter(elem => (elem.match(value) && elem !== value))

    const filtered = useMemo(() => autoComplete.filter(elem => (elem.match(value) && elem !== value)), [autoComplete])

    return (
        <div className="search">
            <input
                value={value}
                onFocus={() => setShow(true)}
                // onBlur={() => setTimeout(() => setShow(false), 500)}
                onChange={e => changeHandler(e.currentTarget.value)}
                type='text' placeholder="Поиск" autoComplete="off"
            />
            {showAutocompl && filtered.length > 0 && (<div className="autocompl">
                {filtered.map((elem, ind) => <div style={{ width: '100%' }} key={ind} onClick={() => {
                    setValue(elem)
                    search(elem, 0)
                }}>{elem}</div>)}
            </div>)}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        autoComplete: state.ispoldocums.autoComplete,
        searchValue: state.ispoldocums.searchValue,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        search: (value, ofset) => dispatch({ type: 'SEARCH_DOCS', value, ofset }),
        closeSearch: () => dispatch({ type: 'END_SEARCH_MODE' }),
        setAutocomplete: (autoComplete) => dispatch({ type: 'SET_AUTOCOMPLETE', autoComplete })
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Search)