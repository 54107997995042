const initialState = {
    isUsers: true,
    currentComp: null,
    currentUser: null,
    isNewUser: false,
    isNewOrg: false
}


export function userplatereducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_LIST_TYPE':
            return {
                ...state,
                isUsers: action.isUsers
            }
        case 'SET_CURRENT_COMP':
            return {
                ...state,
                currentComp: action.currentComp
            }
        case 'SET_CURRENT_USER':
            return {
                ...state,
                currentUser: action.currentUser
            }
        case 'ADD_NEW_USER_MODE':
            return {
                ...state,
                isNewUser: action.isNew
            }
        case 'ADD_NEW_ORG_MODE':
            return {
                ...state,
                isNewOrg: action.isNew
            }
        default:
            return state
    }
}