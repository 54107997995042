import { combineReducers } from 'redux'
import { authreducer } from './authreducer'
import { docreducer } from './docreducer'
import { usersreducer } from './usersreducer'
import { commentreducer } from './commentreducer'
import { idreducer } from './idreducer'
import { fipchangereducer } from './fipchangereducer'
import { notifreducer } from './notifreducer'
import { subscribereducer } from './subscribereducer'
import { offerreducer } from './offerreducer'
import { userplatereducer } from './userplatereducer'
import { meetingsReducer } from './meetingsReducer'
import { factoriesReducer } from './factoriesReducer'


const rootReducer = combineReducers({
    auth: authreducer,
    document: docreducer,
    users: usersreducer,
    comments: commentreducer,
    ispoldocums: idreducer,
    fipchange: fipchangereducer,
    notif: notifreducer,
    subscribe: subscribereducer,
    offer: offerreducer,
    userplate: userplatereducer,
    meetings: meetingsReducer,
    factories: factoriesReducer
})



export default rootReducer