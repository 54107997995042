import React, { useEffect } from 'react'
import ScrollArea from 'react-scrollbar'
import { connect } from 'react-redux'
import MeetingElemUpcoming from './MeetingElemUpcoming'
import MeetingElemPast from './MeetingElemPast'

const MeetingContent = ({
	speed,
	getMeetings,
	meetingsUpcoming,
	meetingsPast,
	setType,
	typeOfPlate,
	activeMeeting,
	maxRole,
	resetActiveMeeting
}) => {
	useEffect(() => {
		getMeetings()
	}, [])

	useEffect(() => {
		if (activeMeeting !== null) {
			return setType('active-meeting')
		}
	}, [activeMeeting])

	return (
		<div
			className={`meeting_content ${
				typeOfPlate === 'meetings' && 'content-blur'
			}`}>
			<ScrollArea
				speed={speed}
				horizontal={false}
				className='meeting_list'
				vertical={true}
				style={{ height: 'calc(100% - 80px)' }}
				smoothScrolling={true}
				verticalContainerStyle={{ width: 6, right: 8 }}
				verticalScrollbarStyle={{ width: 6, marginLeft: 0 }}
				stopScrollPropagation={true}>
				{meetingsUpcoming.length > 0 && (
					<div className='meeting_list-upcoming'>
						<h1 className='meeting_title'>Предстоящие</h1>
						{meetingsUpcoming.map(elem => (
							<MeetingElemUpcoming
								key={elem.id}
								meet={elem}
								setType={setType}
								typeOfPlate={typeOfPlate}
							/>
						))}
					</div>
				)}
				{meetingsPast.length > 0 && (
					<div className='meeting_list-past'>
						<h1 className='meeting_title past'>Прошедшие</h1>
						{meetingsPast.map(elem => (
							<MeetingElemPast
								key={elem.id}
								meet={elem}
								setType={setType}
								typeOfPlate={typeOfPlate}
							/>
						))}
					</div>
				)}
			</ScrollArea>
			{maxRole < 3 && (
				<div className='meeting_content_actions'>
					<div
						className={`btn ${typeOfPlate === 'meetings' && 'disabled'}`}
						onClick={() => {
							resetActiveMeeting()
							setType('meetings')}}>
						Добавить
					</div>
				</div>
			)}
		</div>
	)
}

const mapStateToProps = state => {
	return {
		speed: state.auth.speed,
		meetingsUpcoming: state.meetings.meetingsUpcoming,
		meetingsPast: state.meetings.meetingsPast,
		maxRole: state.auth.maxRole,
		activeMeeting: state.meetings.activeMeeting,
	}
}

const mapDispatchToProps = dispatch => ({
	getMeetings: () => dispatch({ type: 'GET_MEETINGS' }),
	resetActiveMeeting: () => dispatch({ type: 'SET_ACTIVE_MEETING', activeMeeting: null }),
})

export default connect(mapStateToProps, mapDispatchToProps)(MeetingContent)
