import React from 'react';
import { connect } from 'react-redux';

const NotifMenu = ({ menuType, setMenuType }) => {
    return (
        <ul className="notif_menu">
            <li className={menuType === 'ВЛ' ? 'active' : ''} onClick={() => setMenuType('ВЛ')}>ВЛ</li>
            <li className={menuType === 'КЛ' ? 'active' : ''} onClick={() => setMenuType('КЛ')}>КЛ</li>
            <li className={menuType === 'ПС' ? 'active' : ''} onClick={() => setMenuType('ПС')}>ПС</li>
        </ul>
    )
}


const mapStateToProps = state => {
    return {
        menuType: state.notif.menuType
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setMenuType: menuType => dispatch({ type: 'SET_MENU_TYPE', menuType })
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(NotifMenu)