import React, { useEffect, useState } from 'react'
import ScrollArea from 'react-scrollbar'
import { connect } from 'react-redux'
import FileElem from './FileElem'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import ruLocale from 'date-fns/locale/ru'
import {
	MuiPickersUtilsProvider,
	DatePicker,
	TimePicker,
} from '@material-ui/pickers'

const MeetingsPlate = ({ speed, setType, saveMeeting }) => {
	const [completed, setCompleted] = useState(false)
	const [agenda, setAgenda] = useState([undefined])
	const [protocol, setProtocol] = useState([null])
	const [title, setTitle] = useState('')
	const [dateMeeting, setDate] = useState(new Date())
	const [timeMeeting, setTime] = useState(new Date())
	const [editIssue, setEditIssue] = useState('')
	const [editIssueTitle, setEditIssueTitle] = useState('')
	const [issues, setIssues] = useState([])
	const [newIssue, setNewIssue] = useState('')
	const [fileIssue, setFileIssue] = useState([])

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
			<div className='meetings_plate'>
				<ScrollArea
					speed={speed}
					horizontal={false}
					vertical={true}
					smoothScrolling={true}
					verticalContainerStyle={{ width: 6 }}
					verticalScrollbarStyle={{ width: 6, marginLeft: 0 }}
					stopScrollPropagation={true}>
					<h2 className='plate-title'>Новое заседание</h2>
					<form className='meetings_plate-form'>
						<input
							type='text'
							value={title}
							onChange={e => setTitle(e.target.value)}
							placeholder='Заголовок'
							className='meeting-title'
						/>
						<h3 className='plate-subtitle'>Дата и время начала</h3>
						<div className='meeting-time'>
							<DatePicker
								variant='inline'
								format='dd.MM.yyyy'
								margin='normal'
								placeholder='дд.мм.гггг'
								value={dateMeeting}
								onChange={date => setDate(date)}
								className='meeting-date'
							/>
							<TimePicker
								ampm={false}
								disableToolbar
								format='HH:mm'
								variant='inline'
								placeholder='чч:мм'
								value={timeMeeting}
								onChange={time => setTime(time)}
								className='meeting-date'
							/>
						</div>
						<div className='custom-checkbox'>
							<input
								checked={completed}
								onChange={() => setCompleted(!completed)}
								type='checkbox'
								id='check'
							/>
							<label htmlFor='check'>Заседание уже закончено</label>
						</div>
						<div className='fields_upload'>
							<h3 className='plate-subtitle'>Повестка</h3>
							<div className='files'>
								{agenda.length > 0 &&
									agenda
										.filter(i => i !== null)
										.map((elem, ind) => (
											<FileElem
												key={ind}
												arr={agenda}
												setFunc={setAgenda}
												ind={ind}
											/>
										))}
								<div
									className='add-file'
									onClick={() => {
										const clone = agenda.slice()
										clone.push(undefined)
										setAgenda(clone)
									}}>
									<div className='tooltip'>Добавить повестку</div>
								</div>
							</div>
							{completed && (
								<>
									<h3 className='plate-subtitle'>Протокол</h3>
									<FileElem arr={protocol} setFunc={setProtocol} ind={0} />
								</>
							)}
						</div>
						{completed && (
							<div className='meeting-issues'>
								<h3 className='plate-subtitle'>Ключевые вопросы</h3>
								<h4 className='issues-subtitle'>
									Запишите вопросы, которые у вас возникли на заседании
								</h4>
								<div className='meeting-input'>
									<label className='add-file-issues'>
										<input
											type='file'
											hidden
											onChange={e => setFileIssue([e.currentTarget.files[0]])}
										/>
									</label>
									<input
										type='text'
										value={newIssue}
										onChange={e => setNewIssue(e.target.value)}
										placeholder='Вопрос'
										className='meeting-title'
									/>
									<span
										className='send'
										onClick={() => {
											let clone = issues.slice()
											clone.push({
												title: newIssue,
												files: fileIssue,
											})
											setIssues(clone)
											setFileIssue([])
											setNewIssue('')
										}}></span>
								</div>
								{issues.length > 0 &&
									issues.map((elem, idx) => (
										<>
											<div
												key={idx}
												className={`issue-item ${
													editIssue === idx ? 'issue-item-edit' : ''
												}`}>
												{editIssue !== idx && (
													<>
														<div className='issue-title'>
															<div>{elem.title}</div>
															<div className='issue-actions'>
																<span
																	className='issue-edit-icon'
																	onClick={() => {
																		setEditIssue(idx)
																		setEditIssueTitle(elem.title)
																	}}></span>
																<span
																	className='issue-del-icon'
																	onClick={() => {
																		let clone = issues.slice()
																		clone.splice(idx, 1)
																		setIssues(clone)
																	}}></span>
															</div>
														</div>
														{elem.files[0] && elem.files.length > 0 ? (
															<div className='issue-files-list'>
																{elem.files.map((file, i) => (
																	<div key={i} className='issue-file-item'>
																		<span className='issue-file-name'>
																			{file.name}
																		</span>
																	</div>
																))}
															</div>
														) : (
															editIssue !== idx && (
																<span className='issue-nofiles'>
																	Файлы отсутвуют
																</span>
															)
														)}
													</>
												)}
												{editIssue === idx && (
													<>
														<div className='issue-edit'>
															<textarea
																type='text'
																value={editIssueTitle}
																onChange={e =>
																	setEditIssueTitle(e.target.value)
																}
																autoFocus></textarea>
														</div>
														<div className='issue-edit_action-files'>
															<label className='add-file-issue'>
																<input
																	type='file'
																	hidden
																	onChange={e => {
																		let clone = issues.slice()
																		clone[idx].files.push(
																			e.currentTarget.files[0],
																		)
																		setIssues(clone)
																	}}
																/>
															</label>
															{elem.files[0] && elem.files.length > 0 ? (
																<div className='issue-files-list'>
																	{elem.files.map((file, i) => (
																		<div key={i} className='issue-file-item'>
																			<span className='issue-file-name'>
																				{file.name}
																			</span>
																			<span
																				className='issue-file-del'
																				onClick={() => {
																					let clone = issues.slice()
																					clone[idx].files.splice(i, 1)
																					setIssues(clone)
																				}}></span>
																		</div>
																	))}
																</div>
															) : (
																<span className='issue-nofiles'>
																	Файлы отсутвуют
																</span>
															)}
														</div>
													</>
												)}
											</div>
											{editIssue === idx && (
												<div className='issue-edit-actions'>
													<span
														className='issue-complete btn'
														onClick={() => {
															let clone = issues.slice()
															clone[idx].title = editIssueTitle
															setIssues(clone)
															setEditIssue(false)
															setEditIssueTitle('')
														}}>
														Сохранить
													</span>
													<span
														className='issue-del btn'
														onClick={() => {
															setEditIssue('')
															setEditIssueTitle('')
														}}>
														Отмена
													</span>
												</div>
											)}
										</>
									))}
							</div>
						)}
					</form>
				</ScrollArea>
				<div className='meetings_plate-actions justify-flex-end'>
					<div className='btn-group'>
						<div className='link-btn' onClick={() => setType('no-meetings')}>
							Отменить
						</div>
						<div
							className='btn'
							onClick={() => {
								saveMeeting(
									title,
									dateMeeting,
									timeMeeting,
									completed,
									protocol[0],
									agenda,
									issues,
								)
								setType('no-meetings')
							}}>
							Создать
						</div>
					</div>
				</div>
			</div>
		</MuiPickersUtilsProvider>
	)
}

const mapStateToProps = state => ({
	speed: state.auth.speed,
	meetingsUpcoming: state.meetings.meetingsUpcoming,
	meetingsPast: state.meetings.meetingsPast,
})

const mapDispatchToProps = dispatch => ({
	saveMeeting: (
		title,
		dateMeeting,
		timeMeeting,
		completed,
		protocol,
		agenda,
		issues,
	) =>
		dispatch({
			type: 'SAVE_MEETING',
			title,
			dateMeeting,
			timeMeeting,
			completed,
			protocol,
			agenda,
			issues,
		}),
})

export default connect(mapStateToProps, mapDispatchToProps)(MeetingsPlate)
