const initialState = {
    doctype: window.location.pathname.replace('/', ''),
    document: null,
    file: null,
    confirmList: [],
    selectedRmMonth: 3
}


export function docreducer (state=initialState, action) {
    switch (action.type) {
        case 'SET_DOC_TYPE':
            return {
                ...state,
                doctype: action.doctype
            }
        case 'SET_DOC':
            return {
                ...state,
                doctype: action.doctype,
                document: action.document,
                file: action.file,
                confirmList: action.confirmList
            }
        case 'UPDATE_DOCUMENT':
            return {
                ...state,
                file: action.file
            }
        case 'REMOVE_FRAME':
            return {
                ...state,
                file: null
            }
        case 'SET_CONFIRM_LIST':
            return {
                ...state,
                confirmList: action.confirmList
            }
        case 'SET_SELECTED_RM_MONTH':
            return {
                ...state,
                selectedRmMonth: action.month
            }
        default:
            return state
    }
}