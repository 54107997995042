import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';


const NotifAdminElem = ({eventType, docActions, userSubs, sub, unsub, currentUser}) => {
    const [active, setActive] = useState(false)

    useEffect(() => {
        if (userSubs) {
            if (userSubs.find(elem => elem.document_event_type_id == eventType.id)) setActive(true)
            else setActive(false)
        }
    }, [userSubs])

    return (
        <div className="notif_line_elem">
            <div className="elem_title">{docActions[eventType.document_action_id].title}</div>
            <div onClick={() => {
                if (active) unsub(currentUser.id, eventType.id)
                else sub(currentUser.id, eventType.id)
            }} className={`notif_elem_check ${active ? 'checked' : ''}`}>Включить</div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        docActions: state.subscribe.docActions,
        userSubs: state.subscribe.userSubs,
        currentUser: state.userplate.currentUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sub: (userId, eventType) => dispatch({ type: 'SUB_USER', userId, eventType }),
        unsub: (userId, eventType) => dispatch({ type: 'UNSUB_USER', userId, eventType }),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(NotifAdminElem)