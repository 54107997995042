import React, { useState, useRef, useEffect } from 'react';
import ScrollArea from 'react-scrollbar'
import { connect } from 'react-redux';



const SmartSelect = ({ initVal, list, keyOfList, selectFunc, speed, isDisabled }) => {
    const [selected, setSelected] = useState(initVal || '')
    const [filter, setFilter] = useState('')
    const [filterMode, setMode] = useState(false)

    const input = useRef(null)


    useEffect(() => {
        setSelected(initVal)
    }, [initVal])

    useEffect(() => {
        document.addEventListener('click', outside)
        return () => document.removeEventListener('click', outside)
    })

    function clickHandler(val) {
        if (selectFunc) selectFunc(val)
        setMode(false)
        setSelected(val)
        setFilter('')
    }

    function arrowClick() {
        if (input.current) input.current.focus()
    }

    function outside(e) {
        if (filterMode && !e.target.closest('.smart_select')) {
            setMode(false)
            setFilter('')
        }
    }

    return (
        <div className="smart_select">
            <input
                ref={input}
                value={filterMode ? filter : selected ? selected[keyOfList] : ''}
                onChange={e => setFilter(e.currentTarget.value)}
                onFocus={e => {
                    // setFilter(e.currentTarget.value)
                    setMode(true)
                }}
                disabled={isDisabled}
                style={{borderBottomLeftRadius: filterMode ? 0 : 10}}
            />
            <div 
            onClick={() => arrowClick()} 
            style={{borderBottomRightRadius: filterMode ? 0 : 10}}
            className="arrows"></div>
            <ScrollArea
                speed={speed}
                horizontal={false}
                vertical={true}
                className={`select_list ${filterMode ? 'opened_select' : ''}`}
                style={{ height: 208 }}
                smoothScrolling={true}
                verticalContainerStyle={{ width: 4 }}
                verticalScrollbarStyle={{ width: 4, marginLeft: 0 }}
                stopScrollPropagation={true}
            >
                {list.filter(elem => elem[keyOfList].toLowerCase().match(filter.toLocaleLowerCase()))
                .map((elem, ind) => <div key={`${elem[keyOfList]}_${ind}`} onClick={() => clickHandler(elem)} className='select_list_item'>{elem[keyOfList]}</div>)}
            </ScrollArea>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        speed: state.auth.speed
    }
}

export default connect(mapStateToProps)(SmartSelect)
