import React, { useState } from 'react'
import ScrollArea from 'react-scrollbar'
import { connect } from 'react-redux'
import Comment from './Comment'

const CommentList = ({ commentList, inLoad, speed }) => {
	const [counter, setCounter] = useState(0)

	const createComment = (commentList, level, result = null) => {
		let arr = result || []

		commentList.forEach(comment => {
			arr.push(<Comment level={level} key={comment.id} data={comment} />)
			if (comment.childs && comment.childs.length > 0)
				createComment(comment.childs, level + 1, arr)
		})

		return arr
	}

	return (
		<ScrollArea
			speed={speed}
			contentClassName='main_comment_list'
			horizontal={false}
			vertical={true}
			className='comment_list'
			style={{ height: commentList.length > 0 ? 300 : 0 }}
			smoothScrolling={true}
			verticalContainerStyle={{ width: 6 }}
			verticalScrollbarStyle={{ width: 6, marginLeft: 0 }}
			stopScrollPropagation={true}>
			<div className={`${inLoad ? 'comment_in_load' : ''}`}>
				{createComment(commentList, 0)}
			</div>
		</ScrollArea>
	)
}

const mapStateToProps = state => {
	return {
		commentList: state.comments.commentList,
		inLoad: state.comments.inLoad,
		speed: state.auth.speed,
	}
}

export default connect(mapStateToProps)(CommentList)
