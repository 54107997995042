import React, { useState, useEffect } from 'react'
import Collapse from '@material-ui/core/Collapse'
import { connect } from 'react-redux'
import { formatDate } from '../publickFunc'

const MeetingElemPast = ({
	meet,
	setActiveMeeting,
	fetchDoc,
	activeMeeting,
	issues,
	activeIssue,
	loadIssueDoc,
	setType,
	setDocType,
	fetchComments,
	getIssues,
	typeOfPlate,
}) => {
	const [opened, setOpened] = useState(false)
	const [agenda, setAgenda] = useState([])
	const [protocol, setProtocol] = useState([])

	useEffect(() => {
		if (activeMeeting && activeMeeting.files.length > 0) {
			activeMeeting.files
				.filter(elem => elem.type === 'protocol')
				.forEach(i => {
					setProtocol([i])
				})
			const uploadAgenda = activeMeeting.files
				.filter(elem => elem.type === 'agenda')
				.map(i => i)
			setAgenda(uploadAgenda)
		} else {
			setAgenda([undefined])
		}

		const checkProtocol =
			activeMeeting &&
			activeMeeting.files.find(elem => elem.type === 'protocol')

		if (activeMeeting && !checkProtocol) {
			setProtocol([])
		}
	}, [activeMeeting])

	useEffect(() => {
		if (activeMeeting && activeMeeting.id === meet.id) {
			setOpened(true)
			fetchDoc('meetings')
		} else {
			setOpened(false)
		}
	}, [activeMeeting])

	return (
		<div className='meeting_elem'>
			<div
				className='meeting_head'
				onClick={() => {
					getIssues(meet.id)
					setActiveMeeting(meet.id, typeOfPlate)
					if (activeMeeting !== null) setType('active-meeting')
				}}>
				<div>
					<div className='meeting_name'>{meet.title}</div>
					<div className='meeting_start'>
						{meet.date && <span className='date'>{formatDate(meet.date)}</span>}
					</div>
				</div>
				<div className={`meeting_arrow ${opened && 'rotated'}`}></div>
			</div>
			<Collapse in={opened} timeout='auto' unmountOnExit>
				{activeMeeting && activeMeeting.files.length > 0 && (
					<div className='meeting-file-list'>
						{agenda.length > 0 &&
							agenda[0] &&
							agenda.map(
								elem =>
									elem && (
										<div key={elem.id} className='meeting-file'>
											<h5 className='file-title'>Повестка</h5>
											<a
												href={elem.url}
												download={`${elem.name}.${elem.ext}`}
												className='meeting-file-download'>
												<span>{`${elem.name}.${elem.ext}`}</span>
											</a>
										</div>
									),
							)}
						{protocol.length > 0 && (
							<div key={protocol[0].id} className='meeting-file'>
								<h5 className='file-title'>Протокол</h5>
								<a
									href={protocol[0].url}
									download={`${protocol[0].name}.${protocol[0].ext}`}
									className='meeting-file-download'>
									<span>{`${protocol[0].name}.${protocol[0].ext}`}</span>
								</a>
							</div>
						)}
					</div>
				)}
				{issues && issues.length > 0 && (
					<>
						<div className='meeting_line'></div>
						<div className='question'>
							<h2 className='question_title'>Ключевые вопросы</h2>
							{issues.map(elem => (
								<div key={elem.id} className='question_elem'>
									<div className='elem_content'>
										<p>{elem.title}</p>
										{elem.files && elem.files.length > 0 && (
											<div className='meeting-file-list'>
												{elem.files.map(file => (
													<div key={file.id} className='meeting-file'>
														<a
															href={file.url}
															download={`${file.name}.${file.ext}`}
															className='meeting-file-download'>
															<span>{`${file.name}.${file.ext}`}</span>
														</a>
													</div>
												))}
											</div>
										)}
										<div className='question_actions'>
											<div
												onClick={() => {
													activeIssue(elem)
													loadIssueDoc()
													setType('confirm')
												}}>
												Голосование
											</div>
											<div
												onClick={() => {
													activeIssue(elem)
													setDocType('issues')
													setType('comment')
													fetchComments(elem.id)
												}}>
												Комментировать
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</>
				)}
			</Collapse>
		</div>
	)
}

const mapStateToProps = state => ({
	activeMeeting: state.meetings.activeMeeting,
	issues: state.meetings.issues,
})

const mapDispatchToProps = dispatch => ({
	setActiveMeeting: (id, typeOfPlate) =>
		dispatch({ type: 'SELECT_MEETING', id, typeOfPlate }),
	fetchDoc: doctype => dispatch({ type: 'FETCH_DOC', doctype }),
	activeIssue: activeIssue =>
		dispatch({ type: 'SET_ACTIVE_ISSUES', activeIssue }),
	loadIssueDoc: () => dispatch({ type: 'LOAD_DOC_ISSUE' }),
	setDocType: doctype => dispatch({ type: 'SET_DOC_TYPE', doctype }),
	fetchComments: partId => dispatch({ type: 'FETCH_COMMENTS', partId }),
	getIssues: id => dispatch({ type: 'GET_ISSUES', id }),
})
export default connect(mapStateToProps, mapDispatchToProps)(MeetingElemPast)
