const initialState = {
	factories: [],
	activeFactory: null,
	newFactory: false,
}

export function factoriesReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_FACTORIES':
			return {
				...state,
				factories: action.factories,
			}
		case 'SET_ACTIVE_FACTORY':
			return {
				...state,
				activeFactory: action.activeFactory,
			}
		case 'SET_FILE_TKP':
			return {
				...state,
				activeFactory: action.activeFactory,
			}
		case 'SET_NEW_FACTORY':
			return {
				...state,
				newFactory: action.newFactory,
			}
		default:
			return state
	}
}
