import React, { useState } from 'react';
import { connect } from 'react-redux';



const TextComment = ({ sendHandler, delParent }) => {


    const [text, setText] = useState(''),
        [withText, setType] = useState(false)


    function changeHandler(e) {
        setText(e.currentTarget.value)
        if (e.currentTarget.value && !withText) {
            setType(true)
        } else if (!e.currentTarget.value) {
            setType(false)
        }
    }

    return (
        <div className="text_comment">
            <textarea value={text} onChange={changeHandler} />
            <div className="actions">
                <div onClick={() => {
                    sendHandler(text)
                    setText('')
                    setType(false)
                    delParent()
                }} className={`btn ${!withText ? 'passive' : ''}`}>Комментировать</div>
            </div>
        </div>
    )
}





const mapDispatchToProps = dispatch => {
    return {
        delParent: () => dispatch({type: 'SET_PARENT_COMMENT', parent: null})
    }
}

export default connect(null, mapDispatchToProps)(TextComment)