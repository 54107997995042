let initialState = {
    docs: null,
    activeTr: null,
    showActions: false,
    top: null,
    orgs: [],
    searchMode: false,
    searchDocs: [],
    searchValue: '',
    loadingType: null,
    autoComplete: [],
    groups: null,
    companies: [],
    advertVotings: [],
    groupVotings: []
}



export function idreducer (state=initialState, action) {
    switch (action.type) {
        case 'ADD_ISPOLDOCUMS':
            return {
                ...state,
                docs: action.docums
            }
        case 'SET_ACTIVE_TR':
            return {
                ...state,
                activeTr: action.activeTr
            }
        case 'SET_SHOW_ACTIONS':
            return {
                ...state,
                showActions: action.showActions
            }
        case 'SET_TOP':
            return {
                ...state,
                top: action.top
            }
        // case 'ADD_ORGS':
        //     return {
        //         ...state,
        //         orgs: action.orgs,
        //         groups: action.groups,
        //         advertVotings: action.advertVotings,
        //         groupVotings: action.groupVotings
        //     }
        case 'ADD_ORGS':
            return {
                ...state,
                orgs: action.orgs
            }
        case 'ADD_GROUPS':
            return {
                ...state,
                groups: action.groups,
                advertVotings: action.advertVotings,
                groupVotings: action.groupVotings
            }
        case 'ADD_COMPANIES':
            return {
                ...state,
                companies: action.companies
            }
        case 'START_SEARCH_MODE':
            return {
                ...state,
                searchMode: true,
                searchValue: action.searchValue
            }
        case 'SET_SEARCH_DOCS':
            return {
                ...state,
                searchDocs: action.searchDocs
            }
        case 'END_SEARCH_MODE':
            return {
                ...state,
                searchMode: false,
                searchDocs: [],
                searchValue: ''
            }
        case 'SET_LOADING_TYPE':
            return {
                ...state,
                loadingType: action.loadingType
            }
        case 'SET_AUTOCOMPLETE':
            return {
                ...state,
                autoComplete: action.autoComplete
            }
        case 'APPEND_GROUP_VOTING': {
            return {
                ...state,
                groupVotings: action.groupVotings
            }
        }
        default:
            return state
    }
}