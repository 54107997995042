import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import NotifSettings from './NotifSettings';

const statuses = {
    0: 'Ожидаем',
    1: 'Готово'
}

const typeObj = {
    0: 'otsutstvuet',
    1: 'za',
    2: 'protiv'
}

const arrByMenyType = {
    'ВЛ': [1, 2, 3],
    'КЛ': [4, 5, 6],
    'ПС': [7, 8, 9],
}

const NotifTr = ({ title, advertVotings, groupVotings, menuType, isMy, groupId, inLoadNotif, setVotingVal, setSettingMode, setSettingId, maxRole, settingMode, settingId, setSettingPos }) => {

    const [isReady, setReady] = useState(false)
    const [readyArr, setArr] = useState([false, false, false])

    useEffect(() => {
        let a = getReadyElem(groupVotings, advertVotings, menuType, '0,4-20', groupId),
            b = getReadyElem(groupVotings, advertVotings, menuType, '35-220', groupId),
            c = getReadyElem(groupVotings, advertVotings, menuType, '330-1150', groupId),
            arr = [a, b, c]
        setArr(arr)
        if (groupVotings.some(elem => elem.group_id == groupId && elem.value == 1)) setReady(true)
        else setReady(false)
    }, [groupVotings, menuType, advertVotings, groupId])


    // useEffect(() => {
    //     let elem = groupVotings.find(elem => elem.group_id == groupId && arrByMenyType[menuType].includes(elem.advert_voting_type_id))
    //     if (elem) {
    //         setReady(elem.value)
    //     } else {
    //         setReady(false)
    //     }
    // }, [groupVotings, menuType])

    function getReadyElem(groupVotings, advertVotings, menuType, item, groupId) {
        let aVotingId = advertVotings.find(aElem => aElem.section == menuType && aElem.item == item).id
        let vot = groupVotings.find(elem => {
            return elem.group_id == groupId && elem.advert_voting_type_id == aVotingId
        })
        if (vot) {
            return vot.value ? 1 : 2
        }
        return 0
    }

    function clickHandler(isMy, type, item) {
        if (!isMy || type == 0) return
        let votingType = advertVotings.find(elem => elem.section === menuType && elem.item === item).id
        let groupVotingId = groupVotings.find(elem => elem.group_id == groupId && elem.advert_voting_type_id == votingType).id
        let val
        if (type == 1) {
            // removeVoting(groupVotingId, groupId, item)
            val = 0
        } else {
            // addVoting(groupVotingId, groupId, item)
            val = 1
        }
        setVotingVal(groupVotingId, val, groupId, item)
    }

    return (
        <tr className={`${isMy ? 'current_org' : ''}`}
            onContextMenu={e => {
                if (maxRole > 2) return
                let tableTop = e.currentTarget.closest('table').getBoundingClientRect().top,
                    trTop = e.currentTarget.getBoundingClientRect().top
                if (trTop - tableTop < 160) setSettingPos('bottom')
                else setSettingPos('top')
                e.preventDefault()
                setSettingId(groupId)
                setSettingMode()
            }}
        >
            <td className="a_left org_name">
                {title}
                {isMy && <span>Ваш ДЗО</span>}
                {settingMode && (settingId == groupId) && <NotifSettings/>}
            </td>
            <td className={isReady ? 'ready' : 'not_ready'}>
                {isReady ? statuses[1] : statuses[0]}
            </td>
            <td className="with_shadow">
                <div
                    className={`doc ${typeObj[readyArr[0]]} ${(inLoadNotif.groupId == groupId && inLoadNotif.item == '0,4-20' && inLoadNotif.menuType == menuType) ? 'preload_type' : ''}`}
                    onClick={() => clickHandler(isMy, readyArr[0], '0,4-20')}
                ></div>
            </td>
            <td>
                <div
                    className={`doc ${typeObj[readyArr[1]]} ${(inLoadNotif.groupId == groupId && inLoadNotif.item == '35-220' && inLoadNotif.menuType == menuType) ? 'preload_type' : ''}`}
                    onClick={() => clickHandler(isMy, readyArr[1], '35-220')}
                ></div>
            </td>
            <td>
                <div
                    className={`doc ${typeObj[readyArr[2]]} ${(inLoadNotif.groupId == groupId && inLoadNotif.item == '330-1150' && inLoadNotif.menuType == menuType) ? 'preload_type' : ''}`}
                    onClick={() => clickHandler(isMy, readyArr[2], '330-1150')}
                ></div>
            </td>
        </tr>
    )
}

const mapStateToProps = state => {
    return {
        userGroupId: state.auth.userGroupId,
        menuType: state.notif.menuType,
        advertVotings: state.ispoldocums.advertVotings,
        groupVotings: state.ispoldocums.groupVotings,
        inLoadNotif: state.notif.inLoadNotif,
        maxRole: state.auth.maxRole,
        settingMode: state.notif.settingMode,
        settingId: state.notif.settingId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addVoting: (votingType, groupId, item) => dispatch({ type: 'ADD_GROUP_VOTING', votingType, groupId, item }),
        removeVoting: (votingType, groupId, item) => dispatch({ type: 'REMOVE_GROUP_VOTING', votingType, groupId, item }),
        setVotingVal: (groupVotingId, value, groupId, item) => dispatch({type: 'SET_VOTING_VAL', groupVotingId, value, groupId, item}),
        setSettingMode: () => dispatch({type: 'SET_SETTING_MODE', mode: true}),
        setSettingId: (id) => dispatch({type: 'SET_SETTING_ID', settingId: id}),
        setSettingPos: (pos) => dispatch({type: 'SET_SETTING_POS', pos})
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(NotifTr)