import React, { useState, useRef } from 'react'

const FileElem = ({ arr, setFunc, ind }) => {
	const [hover, setHover] = useState(false),
		inputVal = useRef(null)

	const changeFileHandler = (file, arr, setFunc, index) => {
		let clone = arr.slice()
		clone[index] = file
		setFunc(clone)
	}

	return (
		<div
			style={{ background: hover || arr[ind] ? '#F3FBFF' : '' }}
			className='file'
			onDragOver={e => e.preventDefault()}
			onDragEnter={e => setHover(true)}
			onDragLeave={e => {
				if (e.relatedTarget.nodeType === 3 || e.relatedTarget.closest('.file'))
					return
				setHover(false)
			}}
			onDrop={e => {
				e.preventDefault()
				changeFileHandler(e.dataTransfer.files[0], arr, setFunc, ind)
				setHover(false)
			}}>
			{arr[ind] && arr[ind]?.url ? (
				<a href={arr[ind].url} download className={`tooltip elem_with_file `}>
					<span>Файл&nbsp;</span>
					<span>{arr[ind].name}</span>
					<span>&nbsp;загружен</span>
				</a>
			) : (
				<div className={`tooltip ${arr[ind] ? 'elem_with_file' : ''}`}>
					{arr[ind] ? (
						<>
							<span>Файл&nbsp;</span>
							<span>{arr[ind].name}</span>
							<span>&nbsp;загружен</span>
						</>
					) : (
						'Перетяните файл или'
					)}
				</div>
			)}

			{arr[ind] && (
				<div
					className='del_file'
					onClick={() => {
						changeFileHandler(null, arr, setFunc, ind)
						inputVal.current.value = null
					}}></div>
			)}
			<label>
				{arr[ind] ? 'Изменить' : 'Выберите'}
				<input
					ref={inputVal}
					onChange={e =>
						changeFileHandler(e.currentTarget.files[0], arr, setFunc, ind)
					}
					type='file'
				/>
			</label>
		</div>
	)
}

export default FileElem
