import React from 'react';
import { connect } from 'react-redux';
import VotingChangeElem from './VotingChangeElem'
import ScrollArea from 'react-scrollbar'

const VotingListChanger = ({ userList, confirmList, speed, addVoterFunc, removeVoterFunc }) => {

    return (
        <div className="voting_list_changer">
            <ScrollArea
                speed={speed}
                horizontal={false}
                vertical={true}
                style={{ height: '100%' }}
                smoothScrolling={true}
                verticalContainerStyle={{ width: 4 }}
                verticalScrollbarStyle={{ width: 4, marginLeft: 0 }}
                stopScrollPropagation={true}
            >
                {userList.map(elem => {
                    let inList = confirmList.find(listElem => listElem.user_id == elem.id)
                    return (
                        <VotingChangeElem
                            key={elem.id}
                            status={inList ? inList.status.slug : 'ozidaem'}
                            inVoting={inList ? true : false}
                            userName={`${elem.sname} ${elem.name[0]}.`}
                            // voteId={inList ? inList.id : null}
                            // userId={elem.id}
                            addVoter={() => addVoterFunc(elem.id)}
                            removeVoter={inList ? () => removeVoterFunc(inList.id) : null}
                        />
                    )
                })}
            </ScrollArea>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        userList: state.users.userList,
        confirmList: state.document.confirmList,
        speed: state.auth.speed
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addVoterFunc: userId => dispatch({ type: 'ADD_VOTER', userId }),
        removeVoterFunc: voteId => dispatch({ type: 'REMOVE_VOTER', voteId })
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(VotingListChanger)