const initialState = {
    commentList: [], 
    inLoad: false,
    parentComment: null
}


export function commentreducer (state=initialState, action) {
    switch (action.type) {
        case 'ADD_COMMENTS':
            return {
                ...state,
                commentList: action.comments
            }
        case 'COMMENT_IN_LOAD':
            return {
                ...state,
                inLoad: action.inLoad
            }
        case 'SET_PARENT_COMMENT':
            return {
                ...state,
                parentComment: action.parent
            }
        default: 
            return state
    }
}