import React, { useEffect, useState } from 'react'
import ScrollArea from 'react-scrollbar'
import { connect } from 'react-redux'
import FileElem from './FileElem'
import 'date-fns'
import { formatDate } from '../publickFunc'

const FactoriesPlate = ({
	speed,
	setType,
	newFactory,
	factory,
	setActiveFactory,
	saveFactory,
	uploadTkpFiles,
	deleteFileFactory,
}) => {
	const [title, setTitle] = useState('')
	const [activeTitle, setActiveTitle] = useState('')
	const [comment, setComment] = useState('')
	const [TkpID, setTkpID] = useState([])
	const [filesTKP, setFilesTKP] = useState([undefined])
	const [status, setStatus] = useState('C')
	const [statusDate, setStatusDate] = useState()
	const [opened, setOpened] = useState(false)

	useEffect(() => {
		document.addEventListener('click', outsideRefClick)
		return () => document.removeEventListener('click', outsideRefClick)
	})

	const outsideRefClick = e => {
		if (
			opened &&
			(e.target.closest('.select-list') ||
				e.target.closest('.select') ||
				!e.target.closest('.select-opened'))
		)
			setOpened(false)
	}

	useEffect(() => {
		if (factory && !newFactory) {
			if (factory.tkp_files) {
				const tkpFiles = factory.tkp_files.map(i => i)
				setFilesTKP(tkpFiles)
			} else {
				setFilesTKP([undefined])
			}
			setComment(factory.comment)
			setActiveTitle(factory.title)
			setTitle(factory.title)
			setStatus(factory.status)
			setStatusDate(factory.statusMT)
		} else {
			setComment('')
			setActiveTitle('')
			setTitle('')
			setStatus('C')
			setFilesTKP([undefined])
			setStatusDate(null)
		}
	}, [factory, newFactory])

	useEffect(() => {
		if (
			(filesTKP.length === 1) & (filesTKP[0] === null) ||
			filesTKP.length === 0
		)
			setFilesTKP([undefined])
	}, [filesTKP])

	useEffect(() => {
		if (filesTKP && factory) {
			let TkpIDClone = TkpID.slice()
			let filesTKPClone = filesTKP.slice()
			filesTKPClone.forEach(i => {
				if (i !== null && i !== undefined) {
					if (!i.id) {
						uploadTkpFiles(factory.id, i)
					} else {
						TkpIDClone.push(i.id)
						TkpIDClone = Array.from(new Set(TkpIDClone))
					}
				}
			})

			filesTKPClone.forEach((elem, ind) => {
				if (TkpID[ind] && elem === null) {
					deleteFileFactory(factory.id, TkpID[ind])
					filesTKPClone.splice(ind, 1)
					TkpIDClone.splice(ind, 1)
					setFilesTKP(filesTKPClone)
				}
			})
			setTkpID(TkpIDClone)
		}
	}, [filesTKP])

	return (
		<div className='factories__plate'>
			<ScrollArea
				speed={speed}
				horizontal={false}
				vertical={true}
				smoothScrolling={true}
				verticalContainerStyle={{ width: 6 }}
				verticalScrollbarStyle={{ width: 6, marginLeft: 0 }}
				stopScrollPropagation={true}>
				<div className='factories__plate-head'>
					<h2 className='plate-title'>
						{!factory ? 'Новый завод' : activeTitle}
					</h2>
					<div
						className='link-btn'
						onClick={() => {
							setType('no-factories')
							if (factory) setActiveFactory(null)
						}}>
						Отменить
					</div>
				</div>
				<form className='factories__plate-form'>
					<input
						type='text'
						value={title}
						onChange={e => setTitle(e.target.value)}
						placeholder='Наименование'
						className='factories__title'
					/>
					<textarea
						className='factories__comment'
						placeholder='Комментарий'
						value={comment}
						onChange={e => setComment(e.target.value)}
					/>
					<div className='fields_upload'>
						<h3 className='plate-subtitle'>ТКП</h3>
						<div className='files'>
							{filesTKP.length > 0 &&
								filesTKP.map(
									(elem, ind) =>
										elem !== null && (
											<FileElem
												key={ind}
												arr={filesTKP}
												setFunc={setFilesTKP}
												ind={ind}
											/>
										),
								)}
							<div
								className='add-file'
								onClick={() => {
									const clone = filesTKP.slice()
									clone.push(undefined)
									setFilesTKP(clone)
								}}>
								<div className='tooltip'>Добавить ТКП</div>
							</div>
						</div>
					</div>
				</form>
			</ScrollArea>
			<div
				className='factories__plate-actions'
				style={newFactory || !status ? { justifyContent: 'flex-end' } : {}}>
				{!newFactory && factory && (
					<div>
						<h3 className='plate-subtitle'>Статус биллютеня цен</h3>
						<div className={`select ${opened ? 'select-opened' : ''}`}>
							<div onClick={() => setOpened(true)} className='select-arrow'>
								{status === 'C'
									? 'Создан'
									: status === 'L'
									? 'Загружен'
									: 'Запрос'}
							</div>
							<div className='select-list'>
								<span
									className={`select-item ${status === 'C' ? 'active' : ''}`}
									onClick={() => setStatus('C')}>
									Создан
								</span>
								<span
									className={`select-item ${status === 'Q' ? 'active' : ''}`}
									onClick={() => setStatus('Q')}>
									Запрос
								</span>
								<span
									className={`select-item ${status === 'L' ? 'active' : ''}`}
									onClick={() => setStatus('L')}>
									Загружен
								</span>
							</div>
						</div>
						<div className='factories__plate-status'>
							{status === 'C'
								? 'Создан'
								: status === 'L'
								? 'Загружен'
								: 'Запрос'}
							: {formatDate(statusDate)}
						</div>
					</div>
				)}
				<div
					className='btn'
					onClick={() => {
						if (newFactory) {
							saveFactory(null, title, comment, null, filesTKP)
						} else {
							saveFactory(factory.id, title, comment, status)
						}
					}}>
					{newFactory ? 'Добавить' : 'Сохранить'}
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = state => ({
	speed: state.auth.speed,
	newFactory: state.factories.newFactory,
	factory: state.factories.activeFactory,
})

const mapDispatchToProps = dispatch => ({
	setActiveFactory: id => dispatch({ type: 'SET_ACTIVE_FACTORY', id }),
	saveFactory: (id, title, comment, status, filesTKP) =>
		dispatch({
			type: 'SAVE_FACTORY',
			id,
			title,
			comment,
			status,
			filesTKP,
		}),
	uploadTkpFiles: (id, file) => dispatch({ type: 'UPLOAD_TKP', id, file }),
	deleteFileFactory: (id, file) =>
		dispatch({ type: 'DELETE_FILE_FACTORY', id, file }),
})

export default connect(mapStateToProps, mapDispatchToProps)(FactoriesPlate)
