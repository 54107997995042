import React, { useEffect, useState, useContext } from 'react';
import { getIdStatus } from '../publickFunc'
import { connect } from 'react-redux';
import { dateFormatter } from '../publickFunc'
import Tooltip from '@material-ui/core/Tooltip'
import { Context } from './MainContent';


const IdTr = ({ elem, activeTr, setActive, showActions, setTop, isConfirm, setChanger, maxRole, readIdComments }) => {

    const [statusObj, setStatusObj] = useState(null)

    const context = useContext(Context)

    useEffect(() => {
        setStatusObj(getIdStatus(elem))
    }, [elem])

    return (
        <tr
            className={(activeTr && activeTr.id === elem.id) ? 'active_tr' : ''}
            onClick={(e) => {
                showActions(false)
                setActive(elem)
                if (maxRole < 3 || elem.isInUserGroup) {
                    // setActive(elem)
                    if (!isConfirm) return
                    let trCoord = e.target.closest('tr').getBoundingClientRect(),
                        top = 0
                    if (trCoord.top + 510 > document.body.clientHeight) {
                        top = trCoord.bottom - 550
                    } else {
                        top = trCoord.top
                    }
                    setTop(top)
                    showActions(true)
                }

            }}
            onContextMenu={e => {
                e.preventDefault()
                let bottom = document.body.clientHeight - e.currentTarget.getBoundingClientRect().bottom - 10
                setChanger(elem.id, elem, bottom)
            }}
        >
            <td>{elem.fip}</td>
            <td>{elem.group_id.title}</td>
            <td className="obj_title">
                <Tooltip
                    title={elem.name}
                    enterDelay={1000}
                    enterNextDelay={1000}
                    placement="top"
                >
                    <div className="obj_title_block">
                        {elem.name}
                    </div>
                </Tooltip>
                <div onClick={(e) => {
                    e.stopPropagation()
                    if (elem.commentNotifications) readIdComments(elem.commentNotifications.map(comment => comment.id))
                    setActive(elem)
                    context.setType('comment')
                }} className="id_comment_link">
                    {elem.commentNotifications ? <span>Посмотреть комментарии <span className="comment_counter">{elem.commentNotifications.length}</span></span> : (
                        <span className="only_watch_comments">Перейти к комментариям</span>
                    )}
                </div>
            </td>
            <td className="publish">{elem && dateFormatter(elem.updated_at)}</td>
            <td className={statusObj && statusObj.class}>{statusObj && statusObj.name}</td>
            <td className="with_shadow">
                <div data-type="d_pd_id" className={`doc ${!elem.d_pd_id ? 'not_load' : elem.d_pd_id.status.slug}`}></div>
            </td>
            <td>
                <div data-type="d_gepd_id" className={`doc ${!elem.d_gepd_id ? 'not_load' : elem.d_gepd_id.status.slug}`}></div>
            </td>
            <td>
                <div data-type="d_ssr_id" className={`doc ${!elem.d_ssr_id ? 'not_load' : elem.d_ssr_id.status.slug}`}></div>
            </td>
            <td>
                <div data-type="d_sdnf_id" className={`doc ${!elem.d_sdnf_id ? 'not_load' : elem.d_sdnf_id.status.slug}`}></div>
            </td>
            <td>
                <div data-type="d_sdrf_id" className={`doc ${!elem.d_sdrf_id ? 'not_load' : elem.d_sdrf_id.status.slug}`}></div>
            </td>
            <td>
                <div data-type="d_resd_id" className={`doc ${!elem.d_resd_id ? 'not_load' : elem.d_resd_id.status.slug}`}></div>
            </td>
        </tr>
    )
}


const mapStateToProps = state => {
    return {
        activeTr: state.ispoldocums.activeTr,
        maxRole: state.auth.maxRole,
        userGroupId: state.auth.userGroupId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setActive: (activeTr) => dispatch({ type: 'SET_ACTIVE_TR', activeTr }),
        showActions: (show) => dispatch({ type: 'SET_SHOW_ACTIONS', showActions: show }),
        setTop: (top) => dispatch({ type: 'SET_TOP', top }),
        setChanger: (id, fip, bottom) => dispatch({ type: 'SET_FIP_CHANGE', id, fip, bottom }),
        clear: () => dispatch({ type: 'CLEAR_FIP_CHANGE' }),
        readIdComments: (idArr) => dispatch({ type: 'READ_ID_COMMENTS', idArr })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdTr)