import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import IdTr from './IdTr'
import { addScroll, throttle } from '../publickFunc'
import FipChanger from './FipChanger'



const IdTable = ({ docums, isConfirm, orgList, addTitul, fetchDoc, searchMode, searchDocs, appendSearch, searchValue, maxRole, cahngerId }) => {


    const [addMode, setMode] = useState(false)
    const [org, setOrg] = useState(null)
    const [fipName, setFipName] = useState('')
    const [fipTitle, setFipTitle] = useState('')
    const [openOrg, setOpenOrg] = useState(false)
    const [counter, setCounter] = useState(0)
    const [searchCounter, setSearchCounter] = useState(0)
    const [filterVal, setFilterVal] = useState('')


    let content = useRef(null),
        container = useRef(null),
        scroll = useRef(null)


    useEffect(() => {
        setSearchCounter(0)
        content.current.scrollTop = 0
    }, [searchMode, searchValue])

    useEffect(() => {
        if (content.current && container.current && scroll.current) {
            addScroll(content.current, scroll.current, container.current)
        }
    }, [docums, searchDocs, searchMode])

    useEffect(() => {
        document.addEventListener('click', clickHandler)
        return () => { document.removeEventListener('click', clickHandler) }
    }, [])


    function clickHandler(e) {
        let visibleActions = document.querySelector('.visible_org_list')
        if (visibleActions && !e.target.closest('.visible_org_list') && !e.target.closest('.org_select')) setOpenOrg(false)
    }

    return (
        <div onScroll={(e) => {
            if (!docums || docums.length === 0) return
            if (e.currentTarget.scrollHeight - e.currentTarget.clientHeight - e.currentTarget.scrollTop < 2) {
                if (searchMode && (searchCounter + 1) * 15 === searchDocs.length) {
                    appendSearch(searchCounter + 1)
                    setSearchCounter(searchCounter + 1)
                } else if (!searchMode && (counter + 1) * 15 <= docums.length) {
                    fetchDoc('ispoldocums', counter + 1)
                    setCounter(counter + 1)
                }
            }
        }} ref={content} className={`table_wrapper ${!docums ? 'preload_type' : ''}`}>
            {maxRole < 3 && cahngerId && <FipChanger />}
            <div ref={container} style={{height: maxRole < 3 ? 'calc(100% - 132px)' : 'calc(100% - 71px)'}} className="scrollbar_container">
                <div ref={scroll} className="scrollbar"></div>
            </div>
            <table className="id_table">
                <thead>
                    <tr>
                        <th>Ид (код САП)</th>
                        <th className="org">Организация</th>
                        <th className="obj_title">Наименование объекта</th>
                        <th className="publish">Публикация</th>
                        <th className="status">Статус</th>
                        <th className="status_inf with_shadow">ПД</th>
                        <th className="status_inf">ГЭ ПД</th>
                        <th className="status_inf">ССР</th>
                        <th className="status_inf">СД (в н/ф)</th>
                        <th className="status_inf">СД (в р/ф)</th>
                        <th className="status_inf">ГЭ СД</th>
                    </tr>
                </thead>
                <tbody>
                    {searchMode ? searchDocs.map((elem, ind) => <IdTr isConfirm={isConfirm} key={`${elem.id}_${elem.name}_${searchMode}_${ind}`} elem={elem} />) :
                        docums ? docums.map((elem, ind) => <IdTr isConfirm={isConfirm} key={`${elem.id}_${elem.name}_${searchMode}_${ind}`} elem={elem} />) : null}
                    {addMode ? (
                        <tr className="append_tr">
                            <td>
                                <input onChange={(e) => setFipName(e.currentTarget.value)} autoComplete="off" type="text" id="fip" placeholder="Фип" />
                            </td>
                            <td className="org_select">
                                <div onClick={(e) => {
                                    setOpenOrg(!openOrg)
                                }} className="selected_org">{org ? org.title : 'Организация'}</div>
                                <div className={`org_list ${openOrg ? 'visible_org_list' : ''}`}>
                                    <div className="search">
                                        <input placeholder="Поиск" defaultValue={filterVal} onChange={e => setFilterVal(e.currentTarget.value)} />
                                    </div>
                                    {orgList && orgList.filter(elem => {
                                        return (elem.title.toLowerCase().match(filterVal.toLowerCase()) && !elem.title.toLowerCase().match('пао фск еэс') && !elem.title.toLowerCase().match('модэнс') && !elem.title.toLowerCase().match('моденс'))
                                    }).map(elem => {
                                        return (
                                            <div key={elem.id} onClick={() => {
                                                setOrg(elem)
                                                setOpenOrg(false)
                                            }}>{elem.title}</div>
                                        )
                                    })}
                                </div>

                            </td>
                            <td>
                                <input onChange={(e) => setFipTitle(e.currentTarget.value)} autoComplete="off" type="text" id="name" placeholder="Наименование объекта" />
                            </td>
                            <td></td>
                            <td>
                                <div onClick={() => {
                                    addTitul(fipName, fipTitle, org ? org.id : null)
                                    setFipName('')
                                    setFipTitle('')
                                    setOrg(null)
                                    setMode(false)
                                }} className="btn add_titul_btn">Добавить</div>
                            </td>
                            <td>
                                <div className="doc not_load"></div>
                            </td>
                            <td>
                                <div className="doc not_load"></div>
                            </td>
                            <td>
                                <div className="doc not_load"></div>
                            </td>
                            <td>
                                <div className="doc not_load"></div>
                            </td>
                            <td>
                                <div className="doc not_load"></div>
                            </td>
                            <td>
                                <div className="doc not_load"></div>
                            </td>
                        </tr>
                    ) : (
                            <tr className="with_btn">
                                <td colSpan="5" className="with_append">
                                    <div onClick={(e) => {
                                        // e.stopPropagation()
                                        setMode(true)
                                    }} className="append_titul">Добавить титул</div>
                                </td>
                                <td colSpan="6" className="with_shadow"></td>
                            </tr>
                        )}
                </tbody>
            </table>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        docums: state.ispoldocums.docs,
        orgList: state.ispoldocums.orgs,
        searchMode: state.ispoldocums.searchMode,
        searchDocs: state.ispoldocums.searchDocs,
        searchValue: state.ispoldocums.searchValue,
        maxRole: state.auth.maxRole,
        cahngerId: state.fipchange.id
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addTitul: (name, title, orgId) => dispatch({ type: 'ADD_TITUL', name, title, orgId }),
        fetchDoc: (doctype, ofset) => dispatch({ type: 'FETCH_DOC', doctype, ofset }),
        appendSearch: (ofset) => dispatch({ type: 'APPEND_SEARCH_DOCS', ofset })
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(IdTable)