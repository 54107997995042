import { getCookie } from '../../publickFunc'

const initialState = {
    text: getCookie('watched') ? '' : 'Уважаемые коллеги, в рамках разработки сборников и расчетных моделей для расчета ежеквартальных индексов изменения сметной стоимости строительно-монтажных работ по видам объектов капитального строительства в части электросетевого хозяйства, просьба в срок до 23.04.2020 в комментариях к настоящему объявлению предоставить информацию об индексах изменения сметной стоимости СМР (ресурсно-технологических моделях), разработка которых необходима в первую очередь.',
    notifList: [],
    notifDoc: null,
    notifType: null,
    menuType: 'ВЛ',
    inLoadNotif: {
        menuType: null,
        item: null,
        groupId: null
    },
    settingMode: false,
    settingId: null,
    settingPos: null
}


export function notifreducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_NOTIF':
            return {
                ...state,
                text: action.text
            }
        case 'SET_NOTIF_LIST':
            return {
                ...state,
                notifList: action.notifList
            }
        case 'SET_NOTIF_DOC':
            return {
                ...state,
                notifDoc: action.notifDoc
            }
        case 'SET_NOTIF_TYPE':
            return {
                ...state,
                notifType: action.notifType
            }
        case 'SET_MENU_TYPE':
            return {
                ...state,
                menuType: action.menuType
            }
        case 'SET_IN_LOAD_NOTIF':
            return {
                ...state,
                inLoadNotif: action.inLoadNotif
            }
        case 'SET_SETTING_MODE':
            return {
                ...state,
                settingMode: action.mode
            }
        case 'SET_SETTING_ID':
            return {
                ...state,
                settingId: action.settingId
            }
        case 'SET_SETTING_POS':
            return {
                ...state,
                settingPos: action.pos
            }
        default:
            return state
    }
}