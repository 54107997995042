import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { dateFormatter, domain } from '../publickFunc'

const statusObj = {
    'ne-zagruzen': {
        name: "Не загружен",
        id: 1
    },
    "otsutstvuet": {
        name: "Отсутствует",
        id: 2
    },
    "na-proverku": {
        name: "На проверку",
        id: 3
    },
    "ne-prinyat": {
        name: "Не принят",
        id: 4
    },
    "soglasovan": {
        name: "Принят",
        id: 5
    },
}

const ActionPlateElem = ({ activeTr, type, data, setStatus, uploadFile, deleteFile, setLoadingType, loadingType }) => {


    const [openStatus, setOpenStatus] = useState(false),
        [openActions, setOpenActions] = useState(false),
        statusList = useRef(null),
        actionList = useRef(null)


    function statusClick(e) {
        if (openStatus && !e.target.closest('.visible_status_list')) setOpenStatus(false)
    }

    function actionClick(e) {
        if (openActions && (e.target.closest('.open_action_select') != actionList.current)) setOpenActions(false)
    }

    function changeHandler(e) {
        setLoadingType(type)
        let file = e.currentTarget.files[0]
        uploadFile(file, (data && data.id) ? data.id : 0, type.type, activeTr.id)
        setOpenActions(false)
    }

    function downloadHandler() {
        if (data.url_file) {
            window.open(`${data.url_file}`, '_blank')
        } else {
            window.open(`${domain}/document/download/${data.id}`, '_blank')
        }
        setOpenActions(false)
    }




    useEffect(() => {
        document.addEventListener('click', statusClick)
        return () => { document.removeEventListener('click', statusClick) }
    })

    useEffect(() => {
        document.addEventListener('click', actionClick)
        return () => { document.removeEventListener('click', actionClick) }
    })


    return (
        <div data-type="d_pd_id" className={`plate_str ${data ? data.status.slug : 'ne-zagruzen'} ${type === loadingType ? 'preload_type' : ''}`}>
            <div>{type.name}</div>
            <div ref={statusList} onClick={() => setOpenStatus(true)} className="action_plate_status">
                <div className="current_status">
                    <div>{data ? statusObj[data.status.slug].name : 'Не загружен'}</div>
                    {activeTr && activeTr[type.type] && <div className="current_status_date">{dateFormatter(activeTr[type.type].updated_at)}</div>}
                </div>
                <div className={`select_status_list ${openStatus ? 'visible_status_list' : ''}`}>
                    <div onClick={(e) => {
                        e.stopPropagation()
                        setStatus(1, (data && data.id) ? data.id : 0, type.type)
                        setOpenStatus(false)
                    }} data-id="1">Не загружен</div>
                    <div onClick={(e) => {
                        e.stopPropagation()
                        setStatus(2, (data && data.id) ? data.id : 0, type.type)
                        setOpenStatus(false)
                    }} data-id="2">Отсутствует</div>
                    <div onClick={(e) => {
                        e.stopPropagation()
                        setStatus(3, (data && data.id) ? data.id : 0, type.type)
                        setOpenStatus(false)
                    }} data-id="3" className="with_doc">На проверку</div>
                    <div onClick={(e) => {
                        e.stopPropagation()
                        setStatus(4, (data && data.id) ? data.id : 0, type.type)
                        setOpenStatus(false)
                    }} data-id="4" className="with_doc">Не принят</div>
                    <div onClick={(e) => {
                        e.stopPropagation()
                        setStatus(5, (data && data.id) ? data.id : 0, type.type)
                        setOpenStatus(false)
                    }} data-id="5" className="with_doc">Принят</div>
                </div>
            </div>
            <div ref={actionList} className={`plate_actions ${openActions ? 'open_action_select' : ''}`}>
                <div className="visible_action"></div>
                <div onClick={() => setOpenActions(!openActions)} className='arrow'></div>
                <div className="action_select">
                    <div>
                        <label>
                            Загрузить
                            <input onChange={changeHandler} type="file" className="upload_file" />
                        </label>
                    </div>
                    <div onClick={() => downloadHandler()} className="other download_file">Скачать</div>
                    <div onClick={() => {
                        deleteFile(data.id, type.type, activeTr.id)
                        setOpenActions(false)
                    }} className="other">Удалить</div>
                </div>
            </div>
        </div >
    )
}


const mapStateToProps = state => {
    return {
        activeTr: state.ispoldocums.activeTr,
        loadingType: state.ispoldocums.loadingType
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setStatus: (statusId, docId, elemType) => dispatch({ type: 'CHANGE_DOC_STATUS', statusId, docId, elemType }),
        uploadFile: (file, docId, elemType, fipId) => dispatch({ type: 'UPLOAD_FILE', file, docId, elemType, fipId }),
        deleteFile: (docId, elemType, fipId) => dispatch({ type: 'DELETE_FILE', docId, elemType, fipId }),
        setLoadingType: loadingType => dispatch({ type: 'SET_LOADING_TYPE', loadingType })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionPlateElem)