import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import { connect } from 'react-redux';
import NotifLineElem from './NotifLineElem'
import NotifAdminElem from './NotifAdminElem'




const NotifLine = ({ docType, docEventTypes, watchByAdmin, userSubs, currentUser, currentUserId, adminWatchSelf }) => {

    const [opened, setOpened] = useState(false)

    return (
        <div className="notif_line">
            <div className={`notif_line_name ${opened ? 'checked' : ''}`} onClick={() => setOpened(!opened)}>{docType.title}</div>
            <Collapse in={opened} timeout="auto" unmountOnExit>
                {Object.values(docEventTypes).filter(elem => {
                    if (watchByAdmin || adminWatchSelf) {
                        return elem.document_type_id == docType.id
                    } else {
                        return elem.document_type_id == docType.id && userSubs.find(sub => sub.document_event_type_id == elem.id)
                    }
                }).map(elem => {
                    return (watchByAdmin && !adminWatchSelf) ? <NotifAdminElem key={elem.id} eventType={elem}/> : <NotifLineElem adminWatchSelf={adminWatchSelf} key={elem.id} eventType={elem} />
                })}
            </Collapse>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        docEventTypes: state.subscribe.documentEventTypes,
        userSubs: state.subscribe.userSubs,
        currentUser: state.userplate.currentUser, 
        currentUserId: state.users.currentUserId
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}



export default connect(mapStateToProps, mapDispatchToProps)(NotifLine)