import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const SubField = ({ lowRoles, getUserSubs, userSubs, docTypes, docActions, docEventTypes, sub, unsub, setSubMode, currentUser }) => {

    const [currentId, setCurrent] = useState(null)
    const [currentDoc, setCurrentDoc] = useState(null)

    useEffect(() => {
        if (lowRoles[0]) {
            getUserSubs(lowRoles[0].id)
        }
    }, [getUserSubs, lowRoles])

    useEffect(() => {
        if (docTypes.length > 0) setCurrentDoc(docTypes[0].id)
    }, [docTypes])

    useEffect(() => {
        if (lowRoles.length > 0) setCurrent(lowRoles[0].id)
    }, [lowRoles])

    useEffect(() => {
        document.addEventListener('click', outside)
        return () => document.removeEventListener('click', outside)
    })

    function outside(e) {
        if (!e.target.closest('.sub_field')) {
            setSubMode(false)
        }
    }

    return (
        <div className="sub_field_wrapper">
            <div className="sub_field"> 
                <h2>Подписки на уведомления</h2>
                <div>
                    <select value={currentId} onChange={e => {
                        let id = +e.currentTarget.value
                        getUserSubs(id)
                        setCurrent(id)
                    }}>
                        <option value={currentUser}>Мои подписки id: {currentUser}</option>
                        {lowRoles.sort((a,b) => {
                            if (a.sname > b.sname) {
                                return 1
                            } else {
                                return -1
                            }
                        }).map(elem => <option value={elem.id} key={elem.id}>{elem.sname} {elem.name[0]}.{elem.lname[0]}. id: {elem.id}</option>)}
                    </select>
                    <select onChange={e => setCurrentDoc(+e.currentTarget.value)}>
                        {docTypes.map(elem => <option value={elem.id} key={elem.id}>{elem.title} id: {elem.id}</option>)}
                    </select>
                    <div>
                        {userSubs && Object.values(docEventTypes).filter(elem => elem.document_type_id === currentDoc).map(elem => {
                            return (
                                <div key={elem.id}>
                                    <input
                                        type='checkbox'
                                        checked={userSubs.some(sub => sub.document_event_type_id === elem.id)}
                                        id={elem.id}
                                        onChange={e => {
                                            if (e.currentTarget.checked) {
                                                sub(currentId, elem.id)
                                            } else {
                                                unsub(currentId, elem.id)
                                            }
                                        }}
                                    />
                                    <label htmlFor={elem.id}>{docActions[elem.document_action_id].title}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        lowRoles: state.users.lowRoles,
        userSubs: state.subscribe.userSubs,
        docTypes: state.subscribe.docTypes,
        docActions: state.subscribe.docActions,
        docEventTypes: state.subscribe.documentEventTypes,
        currentUser: state.users.currentUserId
    }
}


const mapDispatchToProps = dispatch => {
    return {
        getUserSubs: userId => dispatch({ type: 'GET_USER_SUBS', userId }),
        sub: (userId, eventType) => dispatch({ type: 'SUB_USER', userId, eventType }),
        unsub: (userId, eventType) => dispatch({ type: 'UNSUB_USER', userId, eventType }),
        setSubMode: subMode => dispatch({ type: 'SET_SUB_MODE', subMode }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubField)