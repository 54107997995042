import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import ScrollArea from 'react-scrollbar'
import OrgListElem from './OrgListElem'
import UserListElem from './UserListElem'

const UserPlateList = ({ userList, speed, setType, isUsers, maxRole, addUser, addOrg, isNewUser, companies, currentUser }) => {

    const compList = useMemo(() => companies.filter(elem => elem.group_id).sort((a, b) => {
        if (a.name > b.name) return 1
        else return -1
    }), [companies])

    const usList = useMemo(() => maxRole < 3 ? userList : [currentUser], [maxRole, userList, currentUser])

    return (
        <div className="user_plate_list">
            <div className="user_list_head">
                <ul>
                    <li className={isUsers ? 'active' : ''} onClick={() => {
                        setType(true)
                        addOrg(false)
                    }}>Участники</li>
                    <li className={!isUsers ? 'active' : ''} onClick={() => {
                        setType(false)
                        addUser(false)
                    }}>Организации</li>
                </ul>
            </div>
            <h1>{isUsers ? 'Участники' : 'Организации'}</h1>
            <ScrollArea
                speed={speed}
                horizontal={false}
                vertical={true}
                style={{ height: 'calc(100% - 252px)' }}
                smoothScrolling={true}
                verticalContainerStyle={{ width: 4 }}
                verticalScrollbarStyle={{ width: 4, marginLeft: 0 }}
                stopScrollPropagation={true}
            >
                {isUsers ? (currentUser && usList.map(user => <UserListElem key={user.id} user={user} />)) :
                    (compList.map(comp => <OrgListElem key={comp.id} comp={comp} />))}
            </ScrollArea>
            {maxRole < 3 && isUsers && (<div className="users_actions">
                <div onClick={() => addUser(true)} className={`btn ${isNewUser ? 'passive' : ''}`}>Добавить</div>
            </div>)}
        </div>
    )
}


const stateToProps = state => {
    return {
        userList: state.users.userList,
        speed: state.auth.speed,
        companies: state.ispoldocums.companies,
        isUsers: state.userplate.isUsers,
        maxRole: state.auth.maxRole,
        isNewUser: state.userplate.isNewUser,
        isNewOrg: state.userplate.isNewOrg,
        currentUser: state.userplate.currentUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setType: isUsers => dispatch({ type: 'SET_LIST_TYPE', isUsers }),
        addUser: (isNew) => dispatch({ type: 'ADD_NEW_USER_MODE', isNew }),
        addOrg: (isNew) => dispatch({ type: 'ADD_NEW_ORG_MODE', isNew })
    }
}


export default connect(stateToProps, mapDispatchToProps)(UserPlateList)