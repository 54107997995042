import {getCookie} from '../../publickFunc'


function getBrowser() {
	var ua = navigator.userAgent;
	
    if (ua.search(/MSIE/) > 0) return 'Internet Explorer';
    if (ua.search(/Firefox/) > 0) return 'Firefox';
    if (ua.search(/Opera/) > 0) return 'Opera';
    if (ua.search(/Chrome/) > 0) return 'Google Chrome';
    if (ua.search(/Safari/) > 0) return 'Safari';
    if (ua.search(/Konqueror/) > 0) return 'Konqueror';
    if (ua.search(/Iceweasel/) > 0) return 'Debian Iceweasel';
    if (ua.search(/SeaMonkey/) > 0) return 'SeaMonkey';
	
    // Браузеров очень много, все вписывать смысле нет, Gecko почти везде встречается
    if (ua.search(/Gecko/) > 0) return 'Gecko';

    // а может это вообще поисковый робот
    return 'Search Bot';
}

let browser = getBrowser()


const initialState = {
    accessToken: getCookie("access_token_rg"),
    email: getCookie("email"),
    userName: getCookie('user_name'),
    maxRole: +getCookie('max_role'),
    userGroupId: getCookie('group_id'),
    browser: browser,
    speed: browser === 'Firefox' ? 0.02 : 0.8
}


export function authreducer (state=initialState, action) { 
    switch (action.type) {
        case 'SET_ACCESS_TOKEN':
            return {
                ...state,
                accessToken: action.accessToken,
                email: action.email,
                userName: action.userName,
                maxRole: action.maxRole,
                userGroupId: action.userGroupId
            }
        case 'UPDATE_USER_INFO':
            return {
                ...state,
                email: action.email,
                userName: action.userName,
                maxRole: action.maxRole,
                userGroupId: action.userGroupId
            }
        default:
            return state
    }
}