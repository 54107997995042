import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import ScrollArea from 'react-scrollbar'
import NotifLine from './NotifLine'
import SmartSelect from './SmartSelect'
import SmartSelectMulty from './SmartSelectMulty'
import { roles } from '../publickFunc'
import Password from './Password'

const UserForm = ({ speed, docTypes, isNew, currentUser, maxRole, currentUserId, denyUser, createUser, changeUserInfo, userSubs, documentEventTypes, companies }) => {

    const [sname, setSname] = useState('')
    const [name, setName] = useState('')
    const [lname, setLname] = useState('')
    const [email, setEmail] = useState('')
    const [roleId, setRoleId] = useState('')
    const [company_ids, setCompanyId] = useState([])
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirm] = useState('')
    const [wasChanged, setChanged] = useState(false)
    const [arrOfDocTypes, setArr] = useState([])


    useEffect(() => {
        if (currentUser && !isNew) {
            let userCompanies = currentUser.company_ids ? companies.filter(comp => currentUser.company_ids.includes(comp.id)) : []
            setSname(currentUser.sname)
            setName(currentUser.name)
            setLname(currentUser.lname)
            setEmail(currentUser.email)
            setRoleId(roles.find(role => role.id == currentUser.roles[0].id))
            setCompanyId(userCompanies)
            setPassword('')
            setConfirm('')
        }
    }, [currentUser, isNew, companies])


    useEffect(() => {
        if (userSubs) {
            let arr = userSubs.map(sub => {
                let eventType = Object.values(documentEventTypes).find(elem => elem.id == sub.document_event_type_id)
                if (eventType) return eventType.document_type.id
                return null
            })
            setArr(arr)
        }
    }, [userSubs, documentEventTypes])

    useEffect(() => {
        if (isNew) {
            setSname('')
            setName('')
            setLname('')
            setEmail('')
            setRoleId(null)
            setCompanyId([])
            setPassword('')
            setConfirm('')
        }
    }, [isNew])

    function changeUser(value, changer) {
        if (!isNew && !wasChanged) {
            setChanged(true)
        }
        changer(value)
    }


    function mazafaka(isNew, wasChanged, sname, name, lname, email, roleId, company_ids, password, confirmPassword) {
        let bigBoolOne = (sname && name && lname && email && roleId && (company_ids.length > 0) && password && confirmPassword) ? true : false
        if (isNew) {
            return bigBoolOne
        } else {
            return wasChanged ? true : false
        }
    }


    const filteredCompanies = useMemo(() => companies.filter(comp => comp.group_id), [companies])

    return (
        <div className="user_plate_content user_form">
            <div className="user_form_head">
                <div className="user_plate_avatar">
                    {currentUser && currentUser.id == currentUserId && (
                        <label className="add_avatar">
                            <input type="file" />
                        </label>
                    )}
                </div>
                <div className="user_form_name">
                    {isNew ? 'Новый пользователь' : currentUser ? `${currentUser.sname} ${currentUser.name}` : ''}
                </div>
            </div>
            <ScrollArea
                speed={speed}
                horizontal={false}
                vertical={true}
                style={{ height: 'calc(100% - 230px)', paddingRight: 6 }}
                smoothScrolling={true}
                verticalContainerStyle={{ width: 4 }}
                verticalScrollbarStyle={{ width: 4, marginLeft: 0 }}
                stopScrollPropagation={true}
            >
                <h1>Личная информация</h1>
                <div className="user_info user_name_info">
                    <div>
                        <label>Имя</label>
                        <input
                            value={name}
                            type="text"
                            disabled={maxRole > 2 ? true : false}
                            onChange={e => changeUser(e.currentTarget.value, setName)}
                        />
                    </div>
                    <div>
                        <label>Отчество</label>
                        <input
                            value={lname}
                            type="text"
                            disabled={maxRole > 2 ? true : false}
                            onChange={e => changeUser(e.currentTarget.value, setLname)}
                        />
                    </div>
                    <div>
                        <label>Фамилия</label>
                        <input
                            value={sname}
                            type="text"
                            disabled={maxRole > 2 ? true : false}
                            onChange={e => changeUser(e.currentTarget.value, setSname)}
                        />
                    </div>
                    <div>
                        <label>Email</label>
                        <input
                            value={email}
                            type="text"
                            disabled={maxRole > 2 ? true : false}
                            onChange={e => changeUser(e.currentTarget.value, setEmail)}
                        />
                    </div>
                </div>
                <h1>Деятельность</h1>
                <div className="user_info user_org_info">
                    <div>
                        <label>Организация</label>
                        {/* <SmartSelect
                            initVal={groupId}
                            list={orgs}
                            keyOfList='title'
                            isDisabled={maxRole > 2 ? true : false}
                            selectFunc={val => changeUser(val, setGroupId)}
                        /> */}
                        <SmartSelectMulty
                            initVal={company_ids}
                            list={filteredCompanies}
                            keyOfList='name'
                            selectFunc={val => changeUser(val, setCompanyId)}
                            isDisabled={maxRole > 2 ? true : false}
                        />
                    </div>
                    <div>
                        <label>Роль</label>
                        <SmartSelect
                            initVal={roleId}
                            list={roles}
                            keyOfList='name'
                            isDisabled={maxRole > 2 ? true : false}
                            selectFunc={val => changeUser(val, setRoleId)}
                        />
                    </div>
                </div>
                {currentUser && (maxRole < 3 || currentUser.id == currentUserId) && (
                    <div>
                        <h1>Пароль</h1>
                        <div className="user_info user_password_info">
                            <div>
                                <label>Новый пароль</label>
                                <Password val={password} changeHandler={e => changeUser(e.currentTarget.value, setPassword)}/>
                            </div>
                            <div>
                                <label>Повторите пароль</label>
                                <Password val={confirmPassword} changeHandler={e => changeUser(e.currentTarget.value, setConfirm)}/>
                            </div>
                        </div>
                    </div>
                )}
                {!isNew && currentUser && ((maxRole < 3 && currentUser.roles[0].id > 2) || currentUser.id == currentUserId) && (
                    <div>
                        <h1 style={{ marginBottom: 10 }}>Уведомления</h1>
                        <h2>Выберите уведомления, которые вы хотите получать</h2>
                        {((maxRole < 3) ? docTypes : docTypes.filter(doctype => {
                            return arrOfDocTypes.includes(doctype.id)
                        })).map(doctype => (
                            <NotifLine 
                                key={doctype.id} 
                                docType={doctype} 
                                adminWatchSelf={(currentUser.id == currentUserId) && (maxRole < 3)}
                                watchByAdmin={(maxRole < 3) && currentUser.id != currentUserId} />
                            ))}
                    </div>
                )}
            </ScrollArea>
            {currentUser && (maxRole < 3 || currentUser.id == currentUserId) && (
                <div className="user_form_actions">
                    {isNew && <div onClick={() => denyUser()} className="rej_btn">Отменить</div>}
                    <div
                        onClick={() => {
                            if (isNew) {
                                createUser(sname, name, lname, email, roleId.id, company_ids.map(company => company.id), password, confirmPassword)
                            } else {
                                changeUserInfo(currentUser.id, sname, name, lname, email, roleId.id, company_ids.map(company => company.id), password, confirmPassword)
                            }
                        }}
                        className={`btn ${mazafaka(isNew, wasChanged, sname, name, lname, email, roleId, company_ids, password, confirmPassword) ? '' : 'passive'}`}>
                        {isNew ? 'Сохранить' : 'Изменить'}
                    </div>
                </div>
            )}
        </div>
    )
}


const mapStateToProps = state => {
    return {
        speed: state.auth.speed,
        docTypes: state.subscribe.docTypes,
        orgs: state.ispoldocums.orgs,
        companies: state.ispoldocums.companies,
        isNew: state.userplate.isNewUser,
        currentUser: state.userplate.currentUser,
        maxRole: state.auth.maxRole,
        currentUserId: state.users.currentUserId,
        userSubs: state.subscribe.userSubs,
        documentEventTypes: state.subscribe.documentEventTypes
    }
}

const mapDispatchToProps = dispatch => {
    return {
        denyUser: () => dispatch({ type: 'ADD_NEW_USER_MODE', isNew: false }),
        createUser: (sname, name, lname, email, roleId, company_ids, password, confirmPassword) => dispatch({ type: 'CREATE_USER', sname, name, lname, email, roleId, company_ids, password, confirmPassword }),
        changeUserInfo: (id, sname, name, lname, email, roleId, company_ids, password, confirmPassword) => dispatch({ type: 'CHANGE_USER', id, sname, name, lname, email, roleId, company_ids, password, confirmPassword })
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserForm)