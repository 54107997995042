const initialState = {
    subMode: false,
    docTypes: [],
    docActions: [],
    documentEventTypes: [], 
    userSubs: null
}


export function subscribereducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_SUB_MODE':
            return {
                ...state,
                subMode: action.subMode
            }
        case 'SET_SUB_INFO':
            return {
                ...state,
                docTypes: action.docTypes,
                docActions: action.docActions,
                documentEventTypes: action.documentEventTypes
            }
        case 'SET_USER_SUBS':
            return {
                ...state,
                userSubs: action.userSubs
            }
        default:
            return state
    }
}