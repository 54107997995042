import React, { useEffect } from 'react'
import ScrollArea from 'react-scrollbar'
import { connect } from 'react-redux'

const FactoriesContent = ({
	speed,
	setType,
	maxRole,
	getFactories,
	setNewFactory,
	factories,
	getActiveFactory,
	setActiveFactory,
	activeFactory,
	newFactory,
	deleteFactories,
	fetchDoc,
	setDocType,
	typeOfPlate
}) => {
	useEffect(() => {
		getFactories()
	}, [])

	useEffect(() => {
		if (factories) {
			const factoriesId = factories.map(i => i.id)
			if (activeFactory && !factoriesId.includes(activeFactory.id)) {
				setActiveFactory(null)
				setType('no-factories')
			}
		}
	}, [factories])

	useEffect(() => {
		if (activeFactory && activeFactory.id) {
			setDocType('factories')
			fetchDoc('factories')
			if (maxRole >= 3 && typeOfPlate === 'no-factories') setType('comment')
		}
	}, [activeFactory])

	return (
		<div className='factories'>
			<div className='factories__head'>
				<div className='factories__head-name'>Завод производитель</div>
				<div className='factories__head-status'>Статус</div>
			</div>
			<ScrollArea
				speed={speed}
				horizontal={false}
				className='factories__list'
				vertical={true}
				style={{ height: 'calc(100% - 80px)' }}
				smoothScrolling={true}
				verticalContainerStyle={{ width: 6, right: 8 }}
				verticalScrollbarStyle={{ width: 6, marginLeft: 0 }}
				stopScrollPropagation={true}>
				{factories &&
					factories.length > 0 &&
					factories.map(elem => (
						<div className='factories__item' key={elem.id}>
							<div
								className='factories__name'
								onClick={() => {
									getActiveFactory(elem.id)
									setNewFactory(false)
								}}>
								{elem.title}
							</div>
							{maxRole < 3 && (
								<div className='factories__actions'>
									<span
										className='factories__actions-edit pencil-icon'
										onClick={() => {
											setType('factories')
											getActiveFactory(elem.id)
											if (newFactory) setNewFactory(false)
										}}></span>
									<span
										className='factories__actions-del basket-icon'
										onClick={() => {
											deleteFactories(elem.id)
										}}></span>
								</div>
							)}
							<div
								className={`factories__status ${
									elem.status === 'L' ? 'uploaded' : 'create'
								}`}>
								{elem.status === 'L'
									? 'Загружен'
									: elem.status === 'C'
									? 'Создан'
									: 'Запрос'}
							</div>
						</div>
					))}
			</ScrollArea>
			{maxRole < 3 && (
				<div className='factories__add'>
					<div
						className={`btn`}
						onClick={() => {
							setType('factories')
							setNewFactory(true)
							setActiveFactory(null)
						}}>
						Добавить
					</div>
				</div>
			)}
		</div>
	)
}

const mapStateToProps = state => {
	return {
		factories: state.factories.factories,
		speed: state.auth.speed,
		newFactory: state.factories.newFactory,
		maxRole: state.auth.maxRole,
		activeFactory: state.factories.activeFactory,
	}
}

const mapDispatchToProps = dispatch => ({
	getFactories: () => dispatch({ type: 'GET_FACTORIES' }),
	setNewFactory: newFactory =>
		dispatch({ type: 'SET_NEW_FACTORY', newFactory }),
	getActiveFactory: id => dispatch({ type: 'GET_ACTIVE_FACTORY', id }),
	setActiveFactory: activeFactory =>
		dispatch({ type: 'SET_ACTIVE_FACTORY', activeFactory }),
	deleteFactories: id => dispatch({ type: 'DELETE_FACTORY', id }),
	fetchDoc: doctype => dispatch({ type: 'FETCH_DOC', doctype }),
	setDocType: doctype => dispatch({ type: 'SET_DOC_TYPE', doctype }),
})

export default connect(mapStateToProps, mapDispatchToProps)(FactoriesContent)
