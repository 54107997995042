import React, { useState, useEffect } from 'react'
import Collapse from '@material-ui/core/Collapse'
import TextComment from './TextComment'
import CommentList from './CommentList'
import { connect } from 'react-redux'
import DelDialog from './DelDialog'

const Offer = ({
	title,
	gist,
	date,
	files,
	deleteOffer,
	id,
	userId,
	maxRole,
	currentUserId,
	fetchComments,
	setOfferCommentMode,
	sendComment,
	parentComment,
	users,
	delParent,
}) => {
	const [opened, setOpened] = useState(false)
	const [openedFiles, setOpenedFiles] = useState(false)
	const [openComments, setOpenComments] = useState(false)
	const [dialog, setDialog] = useState(false)
	const [authorName, setAuthor] = useState('')

	let dateObj = new Date(date),
		days = dateObj.getDate(),
		month = dateObj.getMonth() + 1,
		year = dateObj.getFullYear(),
		formatedDate = `${days < 10 ? '0' + days : days}.${
			month < 10 ? '0' + month : month
		}.${year}`

	function downloadFile(url) {
		window.open(url, '_blank')
	}

	useEffect(() => {
		if (parentComment) {
			for (let i = 0; i < users.length; i++) {
				if (users[i].id === parentComment.user_id)
					setAuthor(`${users[i].sname} ${users[i].name}`)
			}
		}
	}, [parentComment])

	return (
		<div style={{ height: 'auto' }} className='offer'>
			<div style={{ filter: dialog ? 'blur(5px)' : '' }}>
				<div
					onClick={() => {
						if (opened) {
							setOpenComments(false)
							setOpenedFiles(false)
						}
						setOpened(!opened)
					}}
					className='offer_name'>
					<div className='name'>{title}</div>
					<div className={`l_arrow ${opened ? 'rotated' : ''}`}></div>
				</div>
				<Collapse in={opened} timeout='auto' unmountOnExit>
					<div className='offer_date'>{formatedDate}</div>
					<div className='offer_gist'>{gist}</div>
					<div
						onClick={() => setOpenedFiles(!openedFiles)}
						className={`files_title ${openedFiles ? 'opened_files' : ''}`}>
						Обоснования
					</div>
					<Collapse
						className='offer_files'
						in={openedFiles}
						timeout='auto'
						unmountOnExit>
						{files.length > 0 ? (
							files.map(elem => {
								return (
									<div
										onClick={() => downloadFile(elem.url)}
										key={elem.id}
										className='offer_file'
										title={`${elem.name}.${elem.ext}`}>
										<div className='offer_file_icon'></div>
										<div className='offer_file_name'>{`${elem.name}.${elem.ext}`}</div>
									</div>
								)
							})
						) : (
							<div>Отсутствуют</div>
						)}
					</Collapse>
					<div
						onClick={() => {
							setOfferCommentMode(true)
							setOpenComments(!openComments)
							fetchComments(id)
						}}
						className='comment_title'>
						<div className='text'>Комментарии</div>
						{/* <div className="counter">7</div> */}
					</div>
					<Collapse in={openComments} timeout='auto' unmountOnExit>
						<div
							className='parent_comment'
							style={{ visibility: parentComment ? '' : 'hidden', height: 34 }}>
							<div style={{ width: '80%' }}>
								<div className='parent_author'>
									{parentComment && authorName}
								</div>
								<div className='parent_body'>
									{parentComment && JSON.parse(parentComment.body)}
								</div>
							</div>
							<div onClick={() => delParent()} className='del_parent'></div>
						</div>
						<TextComment
							sendHandler={text => {
								sendComment(text, id)
							}}
						/>
						<CommentList />
					</Collapse>
					{(+maxRole < 3 || userId == currentUserId) && (
						<div onClick={() => setDialog(true)} className='delete_offer'>
							Удалить предложение
						</div>
					)}
				</Collapse>
			</div>
			{dialog && (
				<DelDialog
					question='Вы уверены, что хотите удалить это предложение?'
					closeHandler={() => setDialog(false)}
					delHandler={() => deleteOffer(id)}
				/>
			)}
		</div>
	)
}

const mapStateToProps = state => {
	return {
		maxRole: state.auth.maxRole,
		currentUserId: state.users.currentUserId,
		parentComment: state.comments.parentComment,
		users: state.users.userList,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		deleteOffer: id => dispatch({ type: 'DELETE_OFFER', id }),
		fetchComments: partId =>
			dispatch({ type: 'FETCH_COMMENTS', isOffer: true, partId }),
		setOfferCommentMode: mode =>
			dispatch({ type: 'SET_OFFER_COMMENT_MODE', mode }),
		sendComment: (text, offerId) =>
			dispatch({ type: 'SEND_COMMENT', text, offerId }),
		delParent: () => dispatch({ type: 'SET_PARENT_COMMENT', parent: null }),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Offer)
