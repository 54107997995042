import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {browser} from './publickFunc'

// Включить если на клиенте нужна поддержка браузера
// browser()


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


