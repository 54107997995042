import React from 'react';
import { connect } from 'react-redux';




const VotingChangeElem = ({ userName, status, inVoting, userId, voteId, addVoter, removeVoter }) => {


    return (
        <div className={`confirm_item ${status}`}>
            <div className="confirm_user">{userName}</div>
            <div onClick={() => inVoting ? removeVoter() : addVoter()} className={`voting_btn ${inVoting && 'in_voting'}`}></div>
        </div>
    )
}


const mapDispatchToProps = dispatch => {
    return {
        // addVoter: userId => dispatch({type: 'ADD_VOTER', userId}),
        // removeVoter: voteId => dispatch({type: 'REMOVE_VOTER', voteId})
    }
}



export default connect(null, mapDispatchToProps)(VotingChangeElem)