import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { shortMonths } from '../publickFunc'


const Comment = ({ data, users, level, addParent, companies }) => {


    let commentDate = new Date(data.created_at),
        h = commentDate.getHours(),
        min = commentDate.getMinutes(),
        d = commentDate.getDate(),
        mon = commentDate.getMonth(),
        y = commentDate.getFullYear(),
        body = JSON.parse(data.body),
        showHours = (((new Date()) - commentDate) / (1000 * 3600 * 24)) < 1,
        author = useMemo(() => users.find(user => user.id === data.user_id), [users, data]),
        compName = useMemo(() => author.company_ids ? companies.filter(comp => author.company_ids.includes(comp.id)).map(comp => comp.name).join(', ') : '', [author, companies])



    return (
        <div style={{ marginLeft: level * 20 }} className={`comment level_${level}`}>
            <div className="comment_info">
                <div className="comment_author">
                    {`${author.sname} ${author.name[0]}. ${author.lname[0]}.`}
                    <div title={compName} className="author_org">{compName}</div>
                </div>
                <div className="comment_time">
                    {showHours ? `${h < 10 ? '0' + h : h}:${min < 10 ? '0' + min : min}` :
                        `${d < 10 ? '0' + d : d} ${shortMonths[mon]} ${y} ${h < 10 ? '0' + h : h}:${min < 10 ? '0' + min : min}`}
                </div>
            </div>
            <div className="comment_body">{body}</div>
            <div className="comment_actions">
                {level === 0 && <div onClick={() => addParent(data)} className="resp_btn">Ответить</div>}
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        users: state.users.userList,
        companies: state.ispoldocums.companies
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addParent: parent => dispatch({ type: 'SET_PARENT_COMMENT', parent })
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Comment)