const initialState = {
    offerList: [],
    addOfferMode: false,
    offerCommentMode: false
}


export function offerreducer (state=initialState, action) {
    switch (action.type) {
        case 'ADD_OFFERS':
            return {
                ...state,
                offerList: action.offerList
            }
        case 'SET_OFFER_MODE':
            return {
                ...state,
                addOfferMode: action.mode
            }
        case 'SET_OFFER_COMMENT_MODE': 
            return {
                ...state,
                offerCommentMode: action.mode
            }
        default:
            return state
    }
}