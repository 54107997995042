import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import ScrollArea from 'react-scrollbar'
import { addScroll } from '../publickFunc'
import NotifTr from './NotifTr'
import NotifSettings from './NotifSettings'

const NotifTable = ({
	orgs,
	menuType,
	groups,
	advertVotings,
	groupVotings,
	maxRole,
}) => {
	const [counter, setCounter] = useState([0, 0, 0])
	const [arrOfGroups, setArr] = useState([])

	let content = useRef(null),
		container = useRef(null),
		scroll = useRef(null)

	useEffect(() => {
		if (!groups || groups.length === 0) return
		if (maxRole < 3) {
			setArr(groups)
		} else {
			let arr = groups.filter(group => {
				let voting = groupVotings.find(elem => elem.group_id == group.id)
				if (voting) return true
				return false
			})
			setArr(arr)
		}
	}, [groups, groupVotings])

	useEffect(() => {
		let a = getCount(menuType, '0,4-20', advertVotings, groupVotings),
			b = getCount(menuType, '35-220', advertVotings, groupVotings),
			c = getCount(menuType, '330-1150', advertVotings, groupVotings)
		setCounter([a, b, c])
	}, [menuType, groups, advertVotings, groupVotings])

	useEffect(() => {
		if (content.current && container.current && scroll.current) {
			addScroll(content.current, scroll.current, container.current)
		}
	}, [groups, menuType])

	function getCount(menuType, item, advertVotings, groupVotings) {
		let idElem = advertVotings.find(
				elem => elem.section == menuType && elem.item == item,
			),
			id
		if (idElem) {
			id = idElem.id
			let vot = groupVotings.filter(
				elem => elem.advert_voting_type_id == id && elem.value == 1,
			)
			return vot.length
		}
		return 0
	}

	return (
		<div
			ref={content}
			className={`table_wrapper ${!groups ? 'preload_type' : ''}`}>
			<div ref={container} className='scrollbar_container'>
				<div ref={scroll} className='scrollbar'></div>
			</div>
			{/* {settingMode && <NotifSettings/>} */}
			<table className='notif_table'>
				<thead>
					<tr>
						<th className='a_left'>Наименование ДЗО ПАО “Россети”</th>
						<th>Статус</th>
						<th className='with_shadow'>
							<div>0.4 - 20</div>
							<div className='unit'>кВ</div>
						</th>
						<th>
							<div>35 - 220</div>
							<div className='unit'>кВ</div>
						</th>
						<th>
							<div>330 - 1150</div>
							<div className='unit'>кВ</div>
						</th>
					</tr>
				</thead>
				<tbody>
					{arrOfGroups.map((group, ind) => {
						return (
							<NotifTr
								isMy={group.isMy}
								groupId={group.id}
								key={group.id}
								title={group.name}
								changeHandler={() => {}}
							/>
						)
					})}
					<tr className='last_tr'>
						<td colSpan='2' className='a_left'>
							Всего
						</td>
						<td className='with_shadow'>
							<div
								className={`counter ${
									counter[0] > counter[1] && counter[0] > counter[2]
										? 'max_counter'
										: ''
								}`}>
								{counter[0]}
							</div>
						</td>
						<td>
							<div
								className={`counter ${
									counter[1] > counter[2] && counter[1] > counter[0]
										? 'max_counter'
										: ''
								}`}>
								{counter[1]}
							</div>
						</td>
						<td>
							<div
								className={`counter ${
									counter[2] > counter[1] && counter[2] > counter[0]
										? 'max_counter'
										: ''
								}`}>
								{counter[2]}
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		orgs: state.ispoldocums.orgs,
		menuType: state.notif.menuType,
		groups: state.ispoldocums.groups,
		advertVotings: state.ispoldocums.advertVotings,
		groupVotings: state.ispoldocums.groupVotings,
		settingMode: state.notif.settingMode,
		maxRole: state.auth.maxRole,
	}
}

export default connect(mapStateToProps)(NotifTable)
